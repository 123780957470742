@charset "UTF-8";
/*** HEADER STYLES ***/
.navbar {
  background-color: #ffffff;
}
.navbar.single-ui-header {
  background-color: rgb(11, 47, 77);
}
.navbar.single-ui-header .container-fluid {
  padding: 16px 40px 0 40px;
}
@media (min-width: 1440px) {
  .navbar.single-ui-header .container-fluid {
    padding: 16px 48px 0 48px;
  }
}
.navbar.single-ui-header .single-ui-container-fluid {
  max-width: 1824px;
}
.navbar.single-ui-header .navbar-header {
  margin-bottom: 8px;
}
.navbar.single-ui-header .navbar-search {
  width: 328px;
  display: flex;
}
.navbar.single-ui-header .navbar-search .form-control {
  height: 40px;
}
.navbar.single-ui-header .navbar-search #headerSearch {
  border: 0px;
  padding: 8px 8px 8px 16px;
  height: 24px;
  box-sizing: content-box;
  border-radius: 4px 0px 0px 4px;
}
.navbar.single-ui-header .navbar-search .btn-default {
  height: 40px;
  border-radius: 0px 4px 4px 0px;
}
.navbar.single-ui-header .navbar-search .btn-default .glyphicon {
  font-size: 16px;
}
.navbar.single-ui-header .navbar-brand {
  padding-top: 0px;
}
.navbar.single-ui-header .navbar-brand img {
  height: 40px;
}
.navbar.single-ui-header .navbar-nav li {
  margin-right: 32px;
}
.navbar.single-ui-header .navbar-nav li a {
  padding: 16px 0px;
  margin: 0px;
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0.15px;
  line-height: 24px;
  height: 100%;
  color: rgb(54, 56, 58);
  border: none;
}
.navbar.single-ui-header .navbar-nav li a.active {
  border-bottom: solid rgb(0, 113, 199);
}
.navbar.single-ui-header .navbar-nav .dropdown-menu {
  width: 244px;
}
.navbar.single-ui-header .navbar-nav .dropdown-menu li {
  margin-right: 0px;
}
.navbar.single-ui-header .navbar-nav .dropdown-menu li a {
  color: rgb(17, 17, 18);
  font-size: 14px;
  letter-spacing: 0.5px;
  display: block;
  padding: 6px 16px 6px 8px;
  text-wrap: wrap;
}
.navbar.single-ui-header .navbar-nav .dropdown-menu li a:hover, .navbar.single-ui-header .navbar-nav .dropdown-menu li a.active {
  color: #108de0;
  background-color: rgba(0, 113, 199, 0.08);
}
.navbar.single-ui-header .single-ui-header-nav {
  display: flex;
  position: absolute;
  right: 0px;
  left: -1px;
  justify-content: center;
  background: #fff;
}
.navbar.single-ui-header .single-ui-header-nav ul {
  max-width: 1824px;
  margin: 0;
}
.navbar.single-ui-header .navbar-icons {
  float: unset !important;
  display: flex;
  justify-content: flex-end;
}
.navbar.single-ui-header #app-chooser-dropdown .navbar-btn {
  color: #FFF;
  margin: 4px;
  padding: 8px;
  border-radius: 100%;
}
.navbar.single-ui-header #app-chooser-dropdown .navbar-btn:hover, .navbar.single-ui-header #app-chooser-dropdown .navbar-btn:focus {
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.08);
}
.navbar.single-ui-header #app-chooser-dropdown .navbar-btn:hover .icon, .navbar.single-ui-header #app-chooser-dropdown .navbar-btn:focus .icon {
  fill: #FFF;
}
@media (max-width: 767px) {
  .navbar.single-ui-header #app-chooser-dropdown .navbar-btn {
    margin-top: 4px;
  }
}
.navbar.single-ui-header #app-chooser-dropdown .icon {
  fill: #FFF;
  width: 16px;
  height: 16px;
  margin: 0px 8px;
}
.navbar.single-ui-header #user-profile-dropdown .navbar-btn {
  color: #FFF;
  border-radius: 26px;
  padding: 4px 16px;
  margin-left: 8px;
  margin-top: 4px;
}
.navbar.single-ui-header #user-profile-dropdown .navbar-btn:hover, .navbar.single-ui-header #user-profile-dropdown .navbar-btn:focus {
  background-color: rgba(0, 0, 0, 0.08);
}
.navbar.single-ui-header #user-profile-dropdown .icon {
  fill: #FFF;
}
.navbar.single-ui-header .userName {
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
}
.navbar.single-ui-header .navisphere-brand {
  width: calc(100% + 80px);
  background-color: #FFF;
  margin-left: -40px;
  padding-left: 40px;
  padding-right: 40px;
}
@media (min-width: 1440px) {
  .navbar.single-ui-header .navisphere-brand {
    width: calc(100% + 96px);
    margin-left: -48px;
    padding-left: 48px;
    padding-right: 48px;
  }
}
.navbar.single-ui-header .navbar-form {
  margin-top: 0px;
  margin-right: 4px;
}
@media (max-width: 767px) {
  .navbar.single-ui-header .navbar-form {
    padding-right: 15px;
  }
  .navbar.single-ui-header .navbar-form .navbar-search {
    width: 100%;
  }
  .navbar.single-ui-header .navbar-form .navbar-search .form-control {
    border-radius: 4px 0px 0px 4px;
  }
  .navbar.single-ui-header .navbar-form .navbar-search .input-group-btn {
    width: unset;
  }
}
.navbar.single-ui-header .navbar-toggle:hover, .navbar.single-ui-header .navbar-toggle:focus {
  background-color: rgba(0, 0, 0, 0.08);
}
.navbar.single-ui-header .navbar-toggle .icon-bar {
  background-color: #FFF;
}

.navbar-brand > img {
  height: 28px;
}

.navisphere-brand {
  color: #676767;
}
.navisphere-brand .dropdown-menu > li > a {
  font-size: 12px;
  color: #676767;
}
.navisphere-brand .dropdown-menu {
  overflow: hidden;
  max-height: none;
}

.navisphere-brand > li > a {
  height: auto;
  font-size: 12px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

.navisphere-brand.navbar-nav > li > a:hover,
.navisphere-brand.navbar-nav > li > a:focus,
.navisphere-brand .dropdown.open > a:hover,
.navisphere-brand .dropdown.open > a:focus {
  background-color: transparent;
  color: #108de0;
}

.navisphere-brand > li > a.active,
.navisphere-brand .dropdown.open > a.active,
.navisphere-brand > li > a.active:hover,
.navisphere-brand > li > a.active:focus {
  color: #108de0;
  font-weight: bold;
  border-bottom: solid #108de0;
  border-width: 0 0 5px 0;
  height: 50px;
}
@media (max-width: 767px) {
  .navisphere-brand > li > a.active,
  .navisphere-brand .dropdown.open > a.active,
  .navisphere-brand > li > a.active:hover,
  .navisphere-brand > li > a.active:focus {
    background: transparent;
    border-bottom: none;
    border-left: thick solid #108de0;
    text-align: left;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

.newNotificationBadge {
  height: 10px;
  width: 10px;
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
}

.newNotificationBadge + a {
  display: inline-block !important;
  padding-left: 10px !important;
}

.nav.navisphere-brand .dropdown-menu > li > a:hover,
.nav.navisphere-brand .dropdown-menu > li > a:focus,
.navbar .navisphere-brand .open .dropdown-menu > li > a:hover,
.navbar .navisphere-brand .open .dropdown-menu > li > a:focus,
.navisphere-brand.navbar-nav .open .dropdown-menu > li > a.active {
  color: #108de0;
  background: transparent;
  border-bottom: none;
}

.navisphere-brand.navbar-nav .open .dropdown-menu > li > a.active {
  font-weight: bold;
}

.navbar-default .navbar-nav > .open > a {
  background: transparent;
}

.navisphere-brand .open > a,
.navisphere-brand .open > a:hover,
.navisphere-brand .open > a:focus {
  border-color: #D6D6D6;
}

.navbar-default .navbar-icons {
  color: #676767;
}
.navbar-default .navbar-icons .caret {
  margin-left: 9px;
}
.navbar-default .navbar-icons .navbar-btn {
  height: 34px;
  background-color: transparent;
  border: none;
  padding: 4px 0px;
  margin: 8px 10px 0 0;
  color: #676767;
}
@media (max-width: 767px) {
  .navbar-default .navbar-icons .navbar-btn {
    margin-top: 12px;
  }
}
.navbar-default .navbar-icons .navbar-btn:hover,
.navbar-default .navbar-icons .navbar-btn:focus {
  color: #108de0;
  border: none;
  background-color: white;
}
.navbar-default .navbar-icons .icon {
  width: 23px;
  height: 23px;
  fill: #676767;
}
.navbar-default .navbar-icons .navbar-btn:hover .icon,
.navbar-default .navbar-icons .navbar-btn:focus .icon {
  fill: #108de0;
}
.navbar-default .navbar-icons .dropdown-menu > li > a {
  color: #676767;
  font-size: 14px;
}
.navbar-default .navbar-icons .dropdown-menu {
  top: 46px;
  margin-right: 10px;
  padding: 5px, 0;
}
.navbar-default .navbar-icons .dropdown-menu .divider {
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-default .navbar-icons .open > a, .navbar-default .navbar-icons .open > a:hover, .navbar-default .navbar-icons .open > a:focus, .navbar-default .navbar-icons .open > a:active, .navbar-default .navbar-icons li > a:hover, .navbar-default .navbar-icons li > a:focus {
  color: #108de0;
  border: none;
  background-color: white;
  cursor: pointer;
}

.app-chooser {
  width: 300px;
  cursor: default;
}
.app-chooser li {
  padding: 0;
}
.app-chooser a {
  display: block;
  text-decoration: none;
  font-weight: 400;
  line-height: 1.42857;
  color: rgb(103, 103, 103);
  clear: both;
  white-space: nowrap;
  cursor: pointer;
  padding: 8px 15px;
}
.app-chooser a:hover {
  color: #108de0;
}
.app-chooser .current-app {
  color: #108de0;
  padding: 8px 12px;
  border-left: 3px solid #108de0;
}

.user-account {
  min-width: 300px;
  padding: 0 5px;
  cursor: default;
}
.user-account h4 {
  margin: 0 0 5px 0;
}
.user-account p {
  margin: 0;
  font-size: 14px;
}
.user-account .circle-border {
  width: 45px;
  height: 45px;
  border: 1px solid #676767;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}
.user-account .circle-border .initials {
  position: relative;
  top: 9px;
  font-size: 18px;
  color: #676767;
  font-weight: bold;
  text-transform: uppercase;
}

.navbar-default .navbar-toggle {
  margin-right: 0;
  border-color: transparent;
}

.navbar-search .form-control {
  height: 34px;
}
.navbar-search .btn.btn-default {
  height: 34px;
  margin: 0;
  padding: 6px 12px;
  background-color: white;
  color: #676767;
  border: none;
}
.navbar-search .btn.btn-default .glyphicon {
  font-size: 23px;
  margin-right: 5px;
}
.navbar-search .btn.btn-default:focus, .navbar-search .btn.btn-default:hover, .navbar-search .btn.btn-default:active {
  color: #108de0;
  box-shadow: none;
}

.navbar-form {
  border: 0;
  padding: 0 0 0 15px;
}

.pageStartingPoint {
  position: relative;
  margin-top: 95px;
  height: 5px;
}

.site-maintenance-banner-section {
  text-align: center;
  background-color: #F5F5F5;
  padding: 3px 0 8px 0;
  z-index: 1;
  width: 970px;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 992px) {
  .site-maintenance-banner-section {
    width: 100%;
    padding-top: 8px;
  }
}

.site-maintenance-banner {
  text-align: center;
  padding: 8px 10px 8px 10px;
  border: 1px solid lightgray;
  border-left: 8px #005CB9 solid;
  background-color: #FFFFFF;
}

.site-maintenance-banner span.strong {
  font-weight: bolder;
}

.site-maintenance-banner-icon {
  margin-right: 10px;
  color: #E8AF01;
}

.site-maintenance-banner-header {
  font-weight: bold;
  margin-right: 3px;
}

.maintenance-banner-close-button {
  display: inline-block;
  margin-left: 13px;
  color: #A2A2A2;
}

.beta-details-page-banner-section {
  text-align: center;
  background-color: #3091bc solid;
  padding: 3px 0 8px 0;
  z-index: 1;
}

.beta-details-page-banner {
  text-align: center;
  padding: 8px 10px 8px 10px;
  border: 1px solid #3091bc;
  border-left: 8px #3091bc solid;
  background-color: #FFFFFF;
  width: 77%;
  margin-top: 45px;
  Cursor: pointer;
}

.beta-details-page-banner span.strong {
  font-weight: bolder;
}

.beta-details-page-banner-icon {
  margin-right: 10px;
  color: #3091bc;
}

.beta-details-page-banner-header {
  font-weight: bold;
  margin-right: 3px;
}

.page-retirement-banner-icon {
  top: 3px;
  font-size: 18px;
  margin-right: 10px;
  color: #005CB9;
}

.page-retirement-banner-text {
  font-size: 14px;
}

/*** FOOTER STYLES ***/
.site-footer {
  background: #ffffff;
  border-top: 1px solid #e7e7e7;
  padding: 20px;
}
.site-footer .single-ui-footer-container {
  max-width: 1824px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 48px;
}
.site-footer .btn-links {
  margin-top: 7px;
}

.switch {
  text-transform: uppercase;
  width: 62px;
  height: 26px;
  color: #0078ae;
  transition: 0.5s ease-out all;
  -webkit-transition: 0.5s ease-out all;
}

.switch small {
  background-color: #0078ae;
  height: 20.8px;
  width: 20.8px;
  top: 2.6px;
  left: 2.6px;
}

.switch.checked {
  background-color: #0078ae;
  border-color: #0078ae;
}

.switch.checked small {
  left: 38.6px;
  background-color: white;
}

.switch .off {
  color: #0078ae;
}

.switch-text {
  line-height: 14px;
}

.switch .on, .switch .off {
  top: 6px;
  line-height: 15px;
}

/***** MAP *****/
.map-container {
  overflow: hidden;
  position: relative;
  height: calc(100vh - 101px);
  margin-top: -35px;
}

.map {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

/***** SEARCH *****/
.map-search .input-group-btn button {
  height: 40px;
  margin: 0;
  padding: 0px 15px;
}

.map-search-clear {
  position: absolute;
  z-index: 1001;
  margin-left: -20px;
  margin-top: 11px;
}

.map-search-clear .glyphicon {
  font-size: 13px;
}

/***** SIDE PANEL *****/
.side-panel {
  height: calc(100vh - 225px);
  overflow-y: auto;
  overflow-x: hidden;
}

.map-panel-header {
  position: relative;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
}
.map-panel-header div {
  width: 75%;
  height: 40px;
}
.map-panel-header h3 {
  margin: 0;
  color: white;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  align-self: center;
}
.map-panel-header a {
  color: white;
}
.map-panel-header a:hover {
  color: whitesmoke;
  cursor: pointer;
}
.map-panel-header img {
  width: 100%;
  height: 100%;
  max-width: 30px;
  max-height: 23px;
}

.map-panel-item {
  padding: 10px;
}

.fixed-bottom-left {
  position: fixed;
  left: 15px;
  bottom: 28px;
}

.btn-oval {
  background-color: #676767;
  color: #fff;
  border-radius: 50px;
  padding: 4px 12px;
  font-size: 12px;
  margin: 0;
}
.btn-oval img {
  width: 20px;
  height: 20px;
  margin-left: -8px;
  margin-right: 3px;
  border-radius: 50%;
}
.btn-oval:hover, .btn-oval:active, .btn-oval:focus {
  background-color: #484848;
  color: #ffffff;
}

.map-selector:hover {
  cursor: pointer;
  background-color: #9999BB;
}

.active-selector {
  background-color: #9999BB;
  border: none;
  outline: 0;
}

/***** INFOBOX *****/
.max-half-height {
  max-height: 50%;
}

.max-full-height {
  max-height: 100%;
}

.infobox-item {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
}

.infobox-data {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.infobox-data-button {
  border-color: #0078ae;
  background-color: #ffffff;
  color: #0078ae;
  border-radius: 5px;
}

.infobox-data-button:hover {
  border-color: #0078ae;
  background-color: #f5f5f5;
  color: #0078ae;
  border-radius: 5px;
}

.infobox-data-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.25em;
}
.infobox-data-row span {
  align-self: center;
}

.infobox-data-row.pin {
  justify-content: flex-start;
  margin-bottom: 0px;
}

.infobox-data-pin {
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 8px;
  align-self: center;
  z-index: 1000;
}

.infobox-data-dotted-line {
  height: 100%;
  min-height: 10px;
  width: 20px;
  min-width: 20px;
  background: url("../app/assets/img/InfoPanel_LocationDot.svg") center 5px repeat-y;
  background-size: 4px 8px;
}

.infobox-data-item {
  border-bottom: thin solid #ECECEC;
  font-size: 13px;
}

/***** MAP MESSAGE OVERLAY *****/
.message-overlay {
  position: fixed;
  bottom: 28px;
  background-color: #676767;
  color: white;
  z-index: 1000;
  border-radius: 5px;
}

/***** FOOTER FOR MAP *****/
.map-footer {
  bottom: 8px;
  left: 55px;
}

.MicrosoftMap .Infobox {
  display: none;
}

.wizard-selections {
  position: relative;
  height: 110px;
  width: 324px;
  padding: 4px;
  border: 1px solid #ddd;
  background: whitesmoke;
  z-index: 10;
  outline: none !important;
}

.wizard-selections:before,
.wizard-selections:after {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  z-index: 10;
}

.wizard-selections.active-widget:before {
  border-width: 1px;
  right: -22px;
  border-left: 21px solid #979797;
  border-top: 54px solid transparent;
  border-bottom: 54px solid transparent;
}

.wizard-selections.active-widget:after {
  border-left: 20px solid white !important;
  border-top: 54px solid transparent;
  border-bottom: 54px solid transparent;
}

.wizard-selections-narrow {
  position: relative;
  height: 90px;
  width: 250px;
  padding: 2px;
  border: 1px solid #ddd;
  background: whitesmoke;
  z-index: 10;
  outline: none !important;
}

.wizard-selections-narrow:before,
.wizard-selections-narrow:after {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  z-index: 10;
}

.wizard-selections-narrow.active-widget:before {
  border-width: 1px;
  right: -22px;
  border-left: 21px solid #979797;
  border-top: 45px solid transparent;
  border-bottom: 45px solid transparent;
}

.wizard-selections-narrow.active-widget:after {
  border-left: 20px solid white !important;
  border-top: 45px solid transparent;
  border-bottom: 45px solid transparent;
}

.wizard-area.active-widget {
  margin-left: 3px;
}

.wizard-area {
  min-height: 200px;
  padding: 4px 4px 40px;
  border-top: 1px solid rgba(151, 151, 151, 0.29);
  margin-bottom: 1px;
  z-index: 10;
  background-color: white;
}
.wizard-area:nth-last-child(2) {
  padding-bottom: 20px;
}

.wizard-widget-scroll-area {
  background-color: #fafafa;
  min-height: 440px;
  height: calc(75vh - 100px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.wizard-widget-scroll-area-long {
  background-color: #fafafa;
  min-height: 540px;
  height: calc(75vh - 100px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.wizard-selection-area {
  padding-left: 10px;
  font-weight: bold;
}

.wizard-selection-area-header {
  line-height: 26px;
  padding-left: 10px;
  vertical-align: middle;
}

.wizard-selection-area-header-center {
  margin-top: 14px;
}

.validator-icon {
  position: absolute;
  top: 0;
  left: 30px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 8px 0;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  border: 1px solid #676767;
  font-size: 150%;
  background-color: whitesmoke;
  color: transparent;
}

.validator-icon.complete {
  color: white;
  background-color: #729514;
}

.active-widget {
  background-color: white;
  border: 1px solid #979797;
  outline: none !important;
}

.wizard-active-widget {
  border: 1px solid whitesmoke;
  margin-bottom: 1px;
  z-index: 10;
  outline: none !important;
}

.create-report-form {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.account-selection-display {
  overflow: auto;
  max-height: 180px;
}

.saved-report-height {
  min-height: 80px;
}

.account-selected {
  font-size: 300%;
  background-color: transparent;
  color: white;
  text-shadow: -1px 0 grey, 0 1px grey, 1px 0 grey, 0 -1px grey;
  line-height: 50px;
  margin-right: 10px;
}

.account-selected.selected {
  color: #0078ae;
}

.account-select-all {
  font-size: 140%;
  background-color: transparent;
  line-height: 22px;
  margin-right: 10px;
}

.account-remove-all {
  font-size: 140%;
  background-color: transparent;
  line-height: 22px;
  margin-right: 10px;
}

.account-widget-scroll-area {
  overflow-y: auto;
  max-height: 230px;
}

.account-panels {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
  text-align: left;
  border: 1px solid #D6D6D6;
}

.header-margin {
  margin-top: 40px;
  margin-bottom: 40px;
}

.saved-report-icons {
  height: 30px;
}

.saved-report-icons span {
  font-size: 130%;
  background-color: transparent;
  line-height: 28px;
  margin-right: 10px;
}

.date-picker-label {
  line-height: 84px;
  vertical-align: middle;
  white-space: nowrap;
}

.split-button {
  height: 26px;
  margin-bottom: 0px;
}

.report-button {
  color: #0078ae;
}

.report-button:hover {
  color: #00008a;
}

.location-selection {
  font-size: 200%;
  background-color: transparent;
  color: white;
  text-shadow: -1px 0 grey, 0 1px grey, 1px 0 grey, 0 -1px grey;
  line-height: 50px;
  margin-right: 10px;
}

.location-selection:hover {
  color: #0078ae;
}

.location-selected {
  font-size: 150%;
  background-color: transparent;
  color: #0078ae;
  line-height: 50px;
  margin-right: 10px;
}

.location-add {
  top: 40px;
  position: absolute;
  font-size: 10px;
  color: #0078ae;
  margin-left: 3px;
}

.location-remove {
  top: 40px;
  position: absolute;
  font-size: 10px;
  color: #0078ae;
  margin-left: -6px;
}

.location-widget-scroll-area {
  overflow-y: auto;
  max-height: 380px;
}

.validator-icon-sm {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 16px;
  height: 16px;
  line-height: 14px;
  margin: 4px 0;
  padding-left: 2px;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  border: 1px solid #676767;
  font-size: 76%;
  background-color: #ececec;
  color: transparent;
}

.validator-icon-sm.complete {
  color: #ffffff;
  background-color: #7b9f1b;
}

.report-subscription-summary {
  display: table;
}
.report-subscription-summary > div {
  display: table-row;
}
.report-subscription-summary > div > div {
  display: table-cell;
}
.report-subscription-summary > div > div:first-child {
  color: gray;
  text-align: right;
}
.report-subscription-summary > div > div:first-child span {
  margin-right: 10px;
}

.subscription-icon {
  height: 20px;
  width: 20px;
}

.error-icon-sm {
  position: absolute;
  top: 4px;
  left: 0px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  margin: 4px 0;
  padding-left: 2px;
  -moz-border-radius: 144%;
  -webkit-border-radius: 144%;
  border-radius: 144%;
  border: 1px solid #676767;
  font-size: 150%;
  border: none;
  background-color: transparent;
  color: #da0303;
}

.close-icon-sm {
  position: absolute;
  width: 18px;
  height: 18px;
  line-height: 18px;
  margin: 6px 22px;
  border: none;
  font-size: 100%;
  background-color: #ffffff;
  color: #676767;
}

.toast-inner-container {
  padding: 0px !important;
  top: -60px;
  height: 60px;
  background-color: white;
  width: 300px;
  margin-right: 40px !important;
}

.toast-message {
  margin-top: 6px;
  color: black;
  font-size: 14px;
  font-weight: bold;
  padding-left: 0px;
}

.toast-sub-message {
  color: black;
  font-size: 10px;
  font-weight: 600;
}

.toast-success-left {
  width: 10px;
  height: 60px;
  background-color: #7b9f1b;
}

.toast-error-left {
  width: 10px;
  height: 60px;
  background-color: #da0303;
}

.toast-success-border {
  border: 1px solid #7b9f1b;
}

.toast-error-border {
  border: 1px solid #da0303;
}

.toast-icon {
  padding-left: 0px !important;
  margin-left: 0px;
}

.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.checkbox label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}

.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9 ;
}

.checkbox + .checkbox {
  margin-top: -5px;
}

.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
}

.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}

input[type=checkbox] {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type=checkbox] {
  margin: 4px 0 0;
}

input[type=checkbox]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

input[type=checkbox] {
  position: relative;
}
input[type=checkbox]::before {
  position: absolute;
  color: rgba(0, 120, 174, 0);
  left: -1px;
  top: -2px;
  width: 16px;
  height: 16px;
  border: 1px solid darkgray;
  cursor: pointer;
  opacity: 1;
}
input[type=checkbox]:not(:disabled)::before {
  background: white;
}
input[type=checkbox]:not(:disabled):checked::before {
  color: #0078ae;
  border-color: #0078ae;
}
input[type=checkbox]:disabled::before {
  background: #ebebe4;
  cursor: not-allowed;
}

input[type=checkbox]::before {
  content: "✔";
  border-radius: 3px;
}
input[type=checkbox]:not(:disabled):checked::before {
  font-size: 14px;
  font-weight: bolder;
  line-height: 1;
  padding-left: 0.5px;
}

.checkbox:nth-child(1) {
  margin-top: 0;
}
.checkbox.disabled {
  color: #adadad;
  cursor: not-allowed;
}

.checkbox-inline {
  margin-right: 10px;
}

label .ns-icon.ns-info::before {
  font-size: 120%;
  cursor: pointer;
}
label.required {
  position: relative;
}
label.required::after {
  background: transparent;
  color: #da0303;
  font-size: 12px;
  padding-left: 5px;
  line-height: 15px;
  top: 5px;
  left: 0;
  border-radius: 50%;
  content: "*";
}

.error-message {
  border-left: 10px solid #da0303;
  padding: 15px;
  background: #ececec;
}
.error-message .glyphicon-remove-sign {
  color: #da0303;
  font-size: 20px;
  float: left;
}

.Truckload-Haz-error-message {
  border-left: 10px solid #da0303;
  padding: 15px;
  background: #ececec;
  margin-bottom: 15px;
}

.Truckload-Haz-error-message h3 {
  color: #da0303;
}

.transactional-tl-button {
  float: left;
  width: 130px;
  height: 120px;
  margin-right: 20px;
  outline: none;
  text-align: center;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 5px #676767;
  display: inline-block;
}

.mode-divider {
  border-left: 1px solid #AAAEB0;
  height: 120px;
  display: inline-block;
  float: left;
  margin-right: 50px;
  margin-left: 30px;
}

.parcel-mode {
  display: inline-block;
}

.external-link {
  display: inline-block !important;
  height: 23px !important;
  width: 23px !important;
  padding-left: 3px;
  padding-bottom: 3px;
}

.parcel-icon {
  height: 54px;
  width: 54px;
  margin-bottom: 7px;
  padding-top: 10px;
}

.centering-icon {
  display: block;
  margin: auto;
}

.center-tl-button-text {
  display: inline-block;
  font-size: 20px;
}

@font-face {
  font-family: "open_sansregular";
  src: url("../../fonts/OpenSans-Regular-webfont.woff") format("woff"), url("../../fonts/OpenSans-Regular-webfont.svg#open_sansregular") format("svg"), url("../../fonts/OpenSans-Regular-webfont.ttf") format("truetype"), url("../../fonts/OpenSans-Regular-webfont.eot"), url("../../fonts/OpenSans-Regular-webfont.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "open_sanssemibold";
  src: url("../../fonts/OpenSans-Semibold-webfont.woff") format("woff"), url("../../fonts/OpenSans-Semibold-webfont.svg#open_sanssemibold") format("svg"), url("../../fonts/OpenSans-Semibold-webfont.ttf") format("truetype"), url("../../fonts/OpenSans-Semibold-webfont.eot"), url("../../fonts/OpenSans-Semibold-webfont.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "open_sansbold";
  src: url("../../fonts/OpenSans-Bold-webfont.woff") format("woff"), url("../../fonts/OpenSans-Bold-webfont.svg#open_sansbold") format("svg"), url("../../fonts/OpenSans-Bold-webfont.ttf") format("truetype"), url("../../fonts/OpenSans-Bold-webfont.eot"), url("../../fonts/OpenSans-Bold-webfont.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "fontello_icons";
  src: url("../../fonts/fontello.woff") format("woff"), url("../../fonts/fontello.svg#fontello_icons") format("svg"), url("../../fonts/fontello.ttf") format("truetype"), url("../../fonts/fontello.eot"), url("../../fonts/fontello.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: "open_sansregular", sans-serif;
  font-size: 12px;
  background-color: whitesmoke; /* bootstrap overrride - bjorn */
  padding-top: 15px;
}

p {
  font-family: "open_sansregular", sans-serif;
  font-size: 12px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "open_sansregular", sans-serif;
  font-weight: normal;
  font-style: normal;
}

/* Page Titles (large) typically*/
h1 {
  font-size: 24px;
  font-family: "open_sanssemibold", sans-serif;
}

h2 {
  font-size: 20px;
}

/* Panel Title*/
h3 {
  font-size: 18px;
}

/* Panel/Section Title*/
h4 {
  font-size: 15px;
}

h5 {
  font-size: 14px;
  font-family: "open_sansbold", sans-serif;
}

h6 {
  font-size: 12px;
  font-family: "open_sansbold", sans-serif;
}

.form-control {
  border-radius: 0;
  border: 1px solid #cccccc;
  font-size: 14px;
  height: 40px;
  line-height: 1;
  padding: 5px 10px;
  color: #363636;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control:focus {
  border-color: #0078ae;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 120, 174, 0.3);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 120, 174, 0.3);
}

.form-control::-moz-placeholder {
  color: #adadad;
  padding-top: 2px;
}

.form-control:-ms-input-placeholder {
  color: #adadad;
  padding-top: 2px;
}

.form-control::-webkit-input-placeholder {
  color: #adadad;
  padding-top: 2px;
}

.input-sm {
  border-color: #cccccc;
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  height: 40px;
  line-height: 1;
  padding: 5px 10px;
  color: #363636;
  -webkit-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
}

.input-sm ::-webkit-input-placeholder {
  color: #adadad;
  font-size: 14px;
  line-height: 1;
}

.input-sm :-moz-placeholder {
  color: #adadad;
  font-size: 14px;
  line-height: 1;
}

.input-sm ::-moz-placeholder {
  color: #adadad;
  font-size: 14px;
  line-height: 1;
}

.input-sm:-ms-input-placeholder {
  color: #adadad;
  font-size: 14px;
  line-height: 1;
}

.input-group .input-group-addon {
  border-radius: 0;
  border-style: solid;
  padding: 5px 10px;
  background: #f5f5f5;
}

.has-error .control-label {
  border-color: #a94442;
  color: #a94442;
  -webkit-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
}

.has-error .input-sm {
  border-color: #a94442;
  border-style: solid;
}

.has-error .input-sm:focus {
  border-color: #a94442;
}

.has-error .input-group-addon {
  background: #f2dede;
  border-color: #a94442;
  color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(218, 3, 3, 0.075), 0 0 8px rgba(218, 3, 3, 0.075);
  box-shadow: inset 0 1px 1px rgba(218, 3, 3, 0.075), 0 0 8px rgba(218, 3, 3, 0.075);
}

input[type=radio] {
  position: absolute;
  width: 30px;
  height: 30px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  opacity: 0;
}

input[type=radio] + label,
input[type=checkbox] + label {
  font-size: 14px;
  line-height: 30px;
  padding: 5px;
}

input[type=radio] + label::before {
  content: "\e80f";
  font-family: "fontello_icons";
  margin-right: 8px;
  line-height: 20px;
  font-size: 28px;
  font-weight: lighter;
  vertical-align: middle;
  color: #adadad;
  padding: 2px;
  border: 1px dotted transparent;
}

input[type=radio]:focus + label::before {
  border: 1px dotted #0078ae;
}

input[type=radio] + label:hover::before {
  color: #0078ae;
}

input[type=radio]:checked + label::before {
  content: "\e810";
  color: #0078ae;
}

input[type=radio][disabled] + label {
  cursor: not-allowed;
  color: #adadad;
}

input[type=radio][disabled] + label::before {
  cursor: not-allowed;
  color: #e0e0e0;
}

.btn {
  font-family: "open_sanssemibold", sans-serif;
  font-size: 20px;
  padding: 12px 25px;
  line-height: 14px;
  border-radius: 0;
  margin-bottom: 5px;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-default {
  color: #0078ae;
  background-color: #ffffff;
  border-color: #0078ae;
  text-transform: capitalize;
}

button.btn-secondary {
  color: #0078ae;
  background-color: #ffffff;
  border-color: #0078ae;
}

button.btn-secondary:hover {
  color: #fff;
  background-color: #0078ae;
  border-color: #0078ae;
}

button.btn-default,
a.btn-default {
  color: #ffffff;
  background-color: #0078ae;
  border-color: #0078ae;
}

button.btn-default:hover,
button.btn-default:focus,
button.btn-default:active,
a.btn-default:hover,
a.btn-default:focus,
a.btn-default:active {
  color: #0078ae;
  background-color: #ffffff;
  border-color: #0078ae;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  color: #ffffff;
  background-color: #0078ae;
  border-color: #0078ae;
}

.btn-group-page-limit {
  line-height: 18px;
  background-color: #fff;
  color: #337ab7;
  border: 1px solid #ddd;
}

.btn-group-page-limit-active {
  line-height: 18px;
  background-color: #337ab7;
  color: #fff !important;
  border: 1px solid #337ab7;
}

.btn-group-radius-right {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.btn-group-radius-left {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.uib-daypicker .btn.btn-default {
  color: #0078ae;
  background-color: #fff;
  border-color: #0078ae;
}

.uib-daypicker:focus,
.uib-daypicker:active {
  outline: none;
}

/* DATEPICKER SCROLLBAR FIX */
.uib-weeks .uib-day button {
  padding: 6px 10px;
}

/* DATEPICKER TITLE HEIGHT FIX */
.uib-daypicker .uib-title {
  line-height: 16px;
}

.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: #ffffff;
  border-color: #cccccc;
  color: #cccccc;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  border-color: #005c85;
}

.btn-primary {
  background-color: #0078ae;
}

.btn-link {
  color: #0078ae;
  font-size: 20px;
  text-decoration: none;
  padding: 12px;
}

.btn-link:hover,
.btn-link:focus {
  color: #0078ae;
  text-decoration: underline;
  background-color: transparent;
}

.btn-link [class^=icon-]:before, .btn-link [class*=" icon-"] {
  font-size: 20px;
}

.btn-link.btn-lg [class^=icon-]:before, .btn-link.btn-lg [class*=" icon-"] {
  font-size: 24px;
}

.btn-link.btn-sm [class^=icon-]:before, .btn-link.btn-sm [class*=" icon-"] {
  font-size: 14px;
}

.btn-link.btn-xs [class^=icon-]:before, .btn-link.btn-xs [class*=" icon-"] {
  font-size: 12px;
}

.btn-link:hover [class^=icon-]:before, .btn-link:hover [class*=" icon-"]:before {
  text-decoration: none;
}

.btn-link.btn-lg {
  padding: 12px;
}

.btn-link.btn-sm {
  padding: 8px;
}

.btn-link.disabled,
.btn-link[disabled],
fieldset[disabled] .btn-link,
.btn-link.disabled:hover,
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link.disabled:focus,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus,
.btn-link.disabled:active,
.btn-link[disabled]:active,
fieldset[disabled] .btn-link:active,
.btn-link.disabled.active,
.btn-link[disabled].active,
fieldset[disabled] .btn-link.active {
  background-color: #ffffff;
  color: #cccccc;
}

.btn-link.btn-link-grey {
  color: #363636;
  text-decoration: none;
}
.btn-link.btn-link-grey:hover {
  color: #363636;
  text-decoration: underline;
}

.btn-lg,
.btn-group-lg > .btn {
  font-size: 26px;
  padding: 12px 25px;
  line-height: 22px;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 5px 10px;
  font-size: 14px;
  font-family: "open_sansregular", sans-serif;
  text-transform: none;
}

.btn-xs,
.btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  font-family: "open_sansregular", sans-serif;
  text-transform: none;
}

.btn-timeframe {
  margin-right: 3px;
}

.btn-pill {
  border-radius: 15px;
}

button.btn-secondary[disabled] {
  border-color: #cccccc;
  color: #cccccc;
}

.am-pm .btn,
.uib-increment .btn {
  margin-bottom: 0px;
}

.panel,
.panel-group,
.panel-heading,
.panel-group .panel {
  border-radius: 0;
}

.panel-default {
  box-shadow: 0px 2px 5px #676767; /* bootstrap overrride - bjorn */
}

.panel-group .panel + .panel {
  margin-top: 10px; /* bootstrap overrride - bjorn */
}

.modal-header {
  background-color: #0078ae;
}

.modal-header .badge {
  color: #fff;
  background: transparent;
  border-radius: 50%;
  border: 2px solid #fff;
  padding: 5px 7px;
  margin: 0 5px;
}

.modal-header .badge.highlighted,
.modal-header .badge:hover {
  background: #fff;
  color: #0078ae;
}

.modal-footer .btn + .btn {
  margin-bottom: 5px;
}

.modal-header h3.modal-title,
.modal-header h3 {
  color: #fff;
  font-weight: bold;
}

.modal-sub-title {
  margin: 10px 0 0 0;
}

@font-face {
  font-family: "navi";
  src: url("../../fonts/navi-glyph-icons.eot");
  src: url("../../fonts/navi-glyph-icons.woff") format("woff"), url("../../fonts/navi-glyph-icons.ttf") format("truetype"), url("../../fonts/navi-glyph-icons.svg") format("svg");
}
.navicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "navi", serif;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  font-size: 3.3em;
  color: #108de0;
}

.navicon-bulk:before {
  content: "B";
}

.navicon-flatbed:before {
  content: "C";
}

.navicon-intermodal:before {
  content: "D";
}

.navicon-ltl:before {
  content: "E";
}

.navicon-tl:before {
  content: "F";
}

.left-seperator {
  border-left: solid 1px #838383;
}

.right-seperator {
  border-right: solid 1px #838383;
}

body {
  min-width: 970px;
}

@media only screen and (max-width: 767px) {
  .site-footer .text-center,
  .site-footer .text-right {
    text-align: left;
  }
}
.container {
  width: 970px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  max-width: none !important;
}

html, body, #content {
  min-height: calc(100vh - 220px);
}

#content {
  margin-top: 20px;
}

/* removes IE10-11 clear input button that clears input values
    without updating angular model because this button isn't firing change event*/
input::-ms-clear {
  display: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.blue-background {
  background-color: #f1f1ff;
}

.uppercase {
  text-transform: uppercase;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

.no-padding {
  padding: 0 !important;
}

.orange-text {
  color: #108de0;
}

.blue-text {
  color: #0078AE;
}

.black-text {
  color: #333;
}

.white-text {
  color: #fff;
}

.gray-text {
  color: #676767;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text !important;
}

.white-circle {
  display: inline-block;
  padding: 10px;
  background-color: #fff;
  border-radius: 50px;
}

/* start header */
.pad-top {
  padding-top: 115px;
}

.padding-bottom {
  padding-bottom: 20px;
}

.small-padding {
  padding-bottom: 10px;
}

.shipment-top-buffer,
.confirmation-top-buffer {
  padding-top: 45px;
}

.order-details-top-buffer {
  padding-top: 10px;
}

.header {
  position: fixed;
  width: 100%;
  margin: 0;
  top: 0;
  z-index: 1000;
  padding: 0 0 0;
  background-color: #676767;
  -webkit-box-shadow: 0 2px 0 #cccccc;
  -ms-box-shadow: 0 2px 0 #cccccc;
  box-shadow: 0 2px 0 #cccccc;
}

.header-btn {
  background: transparent;
  outline: 0;
  border: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  vertical-align: middle;
  padding: 0 10px;
  height: 40px;
}

.header-btn:hover {
  color: rgb(244, 123, 32);
}

.header-btn .icon:hover {
  fill: rgb(244, 123, 32);
}

.header-row-logout {
  border-right: 1px solid #D6D6D6;
  display: table-cell;
}

.header-row-logout a {
  cursor: pointer;
}

.header-icon-container {
  border-right: 1px solid #D6D6D6;
  display: table-cell;
}

.header-icon-container span {
  font-size: 20px;
  cursor: pointer;
}

.header-row-left-cell {
  float: left;
  display: table;
}

.header-row-right-cell {
  float: right;
  border-bottom: 1px solid #D6D6D6;
  color: #fff;
}

.header-row-right-cell span {
  padding: 10px;
}

.header-row {
  width: 970px;
  margin: 0 auto;
  display: table;
}

.header-row-2 {
  /* overflow: hidden; */
  padding-top: 2px;
}

.header-row-cell {
  vertical-align: middle;
  float: left;
}

.header-row-cell img {
  margin-top: 5px;
}

.dashboard-settings {
  white-space: nowrap;
  position: relative;
  display: inline-block;
  margin-top: 3px;
}
.dashboard-settings .dropdown-menu {
  max-height: none;
  min-width: 300px;
  color: #676767;
}
.dashboard-settings .dropdown-menu h5 {
  padding-top: 5px;
  padding-left: 10px;
  margin: 0;
}
.dashboard-settings .dropdown-menu .divider {
  padding-top: 0;
  padding-bottom: 0;
  margin: 5px 0;
}
.dashboard-settings .dropdown-menu li {
  padding: 5px 15px;
}
.dashboard-settings .dropdown-menu .add-btn {
  margin-left: 10px;
}
.dashboard-settings .dropdown-menu .add-btn:hover {
  margin-left: -8px;
  font-weight: 600;
  color: #108de0;
}
.dashboard-settings .dropdown-menu .add-btn:hover .add-glyph {
  fill: #108de0;
  display: block;
}
.dashboard-settings .dropdown-menu .add-glyph {
  display: none;
  float: left;
  margin: 2px 5px 0 0;
  color: rgba(0, 0, 0, 0);
  fill: #108de0;
  width: 14px;
  height: 14px;
}

ul.header-links {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
  margin-top: 10px;
}

ul.header-links li {
  display: inline-block;
}

ul.header-links li a {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  padding: 10px 8px;
  color: #fff;
  text-decoration: none;
}

ul.header-links li:first-of-type a {
  padding-left: 5px;
}

ul.header-links li a:hover, ul.header-links li a.active,
ul.header-links .dropdown-menu li a.active {
  color: rgb(244, 123, 32);
  text-decoration: underline;
}

ul.header-links li a:hover {
  cursor: pointer;
}

ul.header-links .dropdown-menu li {
  width: 100%;
}

ul.header-links .dropdown-menu li a {
  color: #676767;
  padding: 0;
}

ul.header-links .dropdown-menu li a:hover {
  color: #f47b20;
  text-decoration: none;
  background-color: transparent;
}

.header-full-logo {
  padding-top: 10px;
}

.header-search {
  float: right;
  max-width: 20%;
  padding: 0 5px 5px 0;
}

.header-search::before {
  clear: both;
  content: " ";
  display: block;
}

.header-search-cell {
  display: table-cell;
}

.header-icon {
  color: #676767;
}

.header-search-cell input {
  background: #fff;
  color: #696969;
  line-height: 13px;
  font-size: 13px;
  padding: 5px 43px 5px 8px;
  width: 250px;
  border: 1px solid #D6D6D6;
}

.header-external-logo-container {
  position: absolute;
  width: 100%;
}

.header-search-cell input:focus {
  background: #fff;
  border-color: #0078ae;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 120, 174, 0.3);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 120, 174, 0.3);
}

.header-search-cell .header-btn {
  margin-left: -40px;
  color: #838383;
  line-height: 13px;
  margin-top: -3px;
}

.header-search-cell .header-btn:hover,
.header-search-cell .header-btn:focus {
  color: #0078ae;
}

.header-shrink .header-row-1 {
  display: none;
}

.header-row-2 img {
  visibility: hidden;
}

.header-shrink .header-row-2 img {
  visibility: visible;
}

/* end header */
/*user preference*/
.blue-text-large {
  font-size: 20px;
  color: #0078ae;
}

.preference-gray-section {
  background-color: #ececec;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-shadow: 0 2px 0 #cccccc;
  -ms-box-shadow: 0 2px 0 #cccccc;
  box-shadow: 0 2px 0 #cccccc;
}

.preference-section-header {
  border-bottom: 1px solid #ccc;
  line-height: 0;
  padding-left: 15px;
  text-align: left;
  width: 80%;
  margin: auto;
}

.preference-gray-section svg {
  margin-top: 20px;
}

.preference-preview-header {
  border-bottom: 1px solid #ccc;
  line-height: 0;
  padding-left: 15px;
  text-align: left;
  margin: auto;
}

.preference-section-header > span,
.preference-preview-header > span {
  background-color: #ececec;
  padding: 0 10px;
  text-transform: uppercase;
}

#preference-preview-title-span {
  background-color: #fff;
}

.preference-gray-section img {
  padding-top: 15px;
  padding-bottom: 10px;
}

.preference-preview {
  padding-top: 30px;
}

.preference-preview-row {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.preference-preview-radio {
  border: 1px solid #ccc;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 50px;
}

/*end user preference*/
/*Login */
.login-card {
  background: #fff;
  margin-top: -100px;
  padding: 25px;
  border: 1px solid #e7e7e7;
}
.login-card .title {
  margin: 0 0 20px 0;
}

.nav-logo {
  max-width: 250px;
  height: 45px;
  margin: 0 auto 20px auto;
  display: block;
}

.login-error {
  color: #da0303;
}

/* start dashboard */
.create-new-shipment button {
  text-transform: uppercase;
  width: 100%;
}

.create-new-shipment .create-new-shipment-content {
  vertical-align: middle;
}

.create-new-shipment .blue-icon {
  color: #0078AE;
  font-size: 40px;
}

.create-new-shipment button:hover .blue-icon {
  color: #fff;
}

.dashboard-container {
  /*  background: url('../../app/assets/img/dashboard.jpg'); */
  background-color: whitesmoke;
  min-height: 900px;
}

#dash-welcome {
  padding-top: 27px;
  padding-right: 30px;
}

.dashboard-left {
  margin-right: 7px;
  margin-left: 7px;
}

/* .dashboard-item {
  background-color: #676767;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-top: 8px;
  padding-left: 0;
  padding-right: 0;
} */
.dashboard-item-header {
  border-right: solid 1px #676767;
  border-top: solid 1px #676767;
  border-left: solid 1px #676767;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 8px;
}

.dashboard-tracking-content {
  border: solid 1px #676767;
  padding: 16px;
  background-color: white;
}

#create-new-order-btn {
  margin-bottom: 0;
}

.orders-glance-content {
  border-left: solid 1px #676767;
  border-right: solid 1px #676767;
  border-bottom: solid 1px #676767;
  background-color: white;
}

.order-glance-button {
  padding-right: 0;
  padding-left: 0;
  border: none;
  background-color: transparent;
}

.order-glance-box {
  margin: 5px;
  border: solid 1px #0078ae;
  background-color: #F6F6F6;
  color: #0078ae;
  -webkit-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
  /* Firefox 3.5 - 3.6 */
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
  /*Chrome, IE*/
}

.order-glance-box:hover {
  margin: 5px;
  background-color: #0078ae;
  color: white;
  cursor: pointer;
}

.order-glance-column {
  padding-left: 2px;
  padding-right: 2px;
}

.order-glance-title {
  font-size: 13px;
  font-weight: 600;
  height: 30px;
}

.order-glance-number {
  color: rgb(244, 123, 32);
  font-size: 50px;
  font-weight: 500;
}

.dashboard-item-orange-icon {
  color: rgb(244, 123, 32);
  font-size: 30px;
  vertical-align: middle;
}

.dashboard-item-header-content {
  vertical-align: middle;
  margin-left: 10px;
}

.dashboard-item-header-text {
  color: #fff;
  font-size: 20px;
}

.my-recent-col, .my-notification-col {
  padding-right: 0;
}

.mynotifications-row {
  background-color: white;
  margin: 5px 5px 5px 5px;
  padding: 10px 10px 10px 0;
  -webkit-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
}

.remove-notification span {
  font-size: 20px;
}

/*button.remove-notification {
    background-color:#ccc;
    border: none;
    height: 24px;
}*/
/*button.remove-notification:hover {
    background-color: #0078AE;
}*/
button.notification-btn-link.btn-link {
  padding: 0 0 2px 0;
}

.mynotifications-order,
.mynotifications-message {
  padding-top: 3px;
}

/* end dashboard*/
/* Extra margins */
.drop-xxs {
  margin-top: 5px;
}

.drop-xs {
  margin-top: 10px;
}

.drop-sm {
  margin-top: 30px;
}

.drop-md {
  margin-top: 50px;
}

.drop-lg {
  margin-top: 80px;
}

.drop-xl {
  margin-top: 100px;
}

.drop-xxl {
  margin-top: 150px;
}

/* end extra margins */
/* Progress Bar */
.progressbar {
  counter-reset: ctr;
  display: inline-block;
  padding: 0;
  overflow: hidden;
}

.progressbar a {
  display: flex;
  align-items: center;
  text-align: left;
  float: left;
  position: relative;
  text-decoration: none;
  outline: none;
  height: 36px;
  max-width: 135px;
  padding: 0 16px 0 55px;
  font-size: 10px;
}

.progressbar a:first-child {
  padding: 0 16px 0 35px;
}

.progressbar a.active:after,
.progressbar a:hover:after {
  -moz-box-shadow: none;
  -o-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.progressbar a:after {
  content: "";
  position: absolute;
  top: 0;
  right: -18px;
  width: 36px;
  height: 36px;
  z-index: 1;
  -moz-transform: scale(0.707) rotate(45deg);
  -ms-transform: scale(0.707) rotate(45deg);
  -o-transform: scale(0.707) rotate(45deg);
  -webkit-transform: scale(0.707) rotate(45deg);
}

.progressbar a:last-child:after {
  content: none;
}

.progressbar a:before {
  content: counter(ctr);
  counter-increment: ctr;
  position: absolute;
  top: 0;
  left: 30px;
  width: 19px;
  height: 19px;
  line-height: 19px;
  margin: 8px 0;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.progressbar a:first-child:before {
  left: 10px;
}

.progressbar a.complete::before,
.progressbar a.complete::before {
  content: "\e013";
  font-family: "Glyphicons Halflings", serif;
  font-size: 10px;
}

.progressbar a.error::before,
.progressbar a.error::before {
  content: "\e101";
  font-family: "Glyphicons Halflings", serif;
  font-size: 19px;
}

.progressbar a.inactive,
.progressbar a.inactive:hover,
.progressbar a.inactive::before,
.progressbar a.inactive:hover::before,
.progressbar a.inactive:hover::after {
  cursor: default;
}

/* flatter theme*/
.progressbar.flat {
  border: 1px solid #aaa;
}

.progressbar.flat a {
  background: #fff;
  color: #262626;
  cursor: pointer;
}

.progressbar.flat a:after {
  background: #fff;
  box-shadow: 2px -2px 0 0 rgba(0, 0, 0, 0.4), 1px -1px 0 1px rgba(255, 255, 255, 0.1);
  -moz-box-shadow: 1px -1px 0 0 rgba(0, 0, 0, 0.4), 1px -1px 0 1px rgba(255, 255, 255, 0.1);
  -o-box-shadow: 1px -1px 0 0 rgba(0, 0, 0, 0.4), 1px -1px 0 1px rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: 1px -1px 0 0 rgba(0, 0, 0, 0.4), 1px -1px 0 1px rgba(255, 255, 255, 0.1);
  border-radius: 0 1px 0 10px;
}

.progressbar.flat a:before {
  background: #fff;
  box-shadow: 0 0 0 1px #ccc;
  -moz-box-shadow: 0 0 0 1px #ccc;
  -o-box-shadow: 0 0 0 1px #ccc;
  -webkit-box-shadow: 0 0 0 1px #ccc;
  text-align: center;
}

.progressbar.flat a.inactive,
.progressbar.flat a.inactive:hover,
.progressbar.flat a.inactive::before,
.progressbar.flat a.inactive:hover::before,
.progressbar.flat a.inactive:hover::after {
  background: #fff;
  color: #999;
}

.progressbar.flat a.step-disabled,
.progressbar.flat a.step-disabled::after {
  cursor: not-allowed;
}

.progressbar.flat a.complete::before,
.progressbar.flat a.complete::before {
  background-color: #729a14;
  color: white;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.progressbar.flat a.complete,
.progressbar.flat a.complete::after {
  background-color: white;
  color: #729514;
}

.progressbar.flat a:hover,
.progressbar.flat a:hover::after {
  background: #EEF5E9;
}

.progressbar.flat a.active,
.progressbar.flat a.active:after {
  background: #d9f4bf;
}

.progressbar.flat a.error::before,
.progressbar.flat a.error::before {
  background-color: white;
  color: #da0303;
  box-shadow: none;
  -webkit-box-shadow: none;
}

/* End Progress Bar*/
.glyphicon-ok-green {
  background-color: #729a14;
  color: white;
  width: 19px;
  height: 19px;
  line-height: 19px;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  text-align: center;
}

.glyphicon-exclamation-sign-red {
  background-color: white;
  color: #da0303;
  cursor: default;
  font-size: 19px;
}

/* Progress Bar Header*/
#progressBarHeader-container,
#subheader-container {
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 82px;
  z-index: 500;
  -webkit-box-shadow: 2px 2px 5px #cccccc;
  -ms-box-shadow: 2px 2px 5px #cccccc;
  box-shadow: 2px 2px 5px #cccccc;
}

#progressBarHeader,
#subheader {
  min-height: 60px;
  background-color: #fff;
  display: table;
  width: 970px;
  margin: 0 auto;
  padding: 2px 5px;
}

#progressBarHeader .progressbar {
  margin-top: 11px;
}

#orderHeaderLabel, #order-detail-header-label,
#myorder-header-label, #quote-header-label {
  color: #108de0;
  vertical-align: middle;
  padding-right: 5px;
}

#orderHeaderLabel {
  display: table-cell;
  text-align: left;
  max-width: 135px;
}

#order-detail-view-bol-link {
  font-size: larger;
  margin-bottom: 5px;
}
#order-detail-view-bol-link .action-bay {
  display: inline-block;
  float: right;
}

#order-detail-view-bol-link a {
  cursor: pointer;
  display: block;
}

#order-detail-view-bol-link a:hover svg {
  color: transparent;
  fill: #0078AE;
}

#order-detail-status-label .canceled-status {
  color: #da0303;
  font-weight: bolder;
}

/* End Progress Bar Header*/
/* Create Order*/
.order-container, .order-details-container,
.myorder-container, .quote-container {
  background-color: rgb(245, 245, 245);
}

.shipment-left-Margin {
  margin-left: 20px;
}

.ui-select-bootstrap .btn {
  font-size: 14px;
  padding: 12px 10px;
  font-family: "open_sans", sans-serif;
}

.ui-select-bootstrap .btn:hover {
  background: #E6E6E6;
  color: #333;
  border-color: #ADADAD;
}

.ui-select-bootstrap .btn-default {
  border-color: #CCC;
  color: #333333;
}

.has-error .ui-select-bootstrap .btn-default {
  border-color: #a94442;
  color: #333333;
}

span.btn.ui-select-toggle {
  margin-bottom: 0;
}

.ui-select-search {
  font-family: "open_sans", sans-serif;
}

.customerDropDownHeader {
  font-weight: bold;
}

.recentCustomers, .recentLocations {
  border-left: thin solid #ADADAD;
  min-height: 205px;
}

.mode-image {
  width: 100%;
}

.shipping-method-icon-text {
  width: 100%;
}

.orderMode {
  padding-left: 20px;
  padding-right: 20px;
}

.orderMode .form-group {
  text-align: center;
}

.orderMode input[type=radio] + label {
  padding: 0;
}

.orderMode input[type=radio] + label::before {
  margin-right: 0;
}

.orderMode .radio label {
  padding-left: 0;
}

.orderMode img {
  margin-bottom: 20px;
}

textarea.location {
  resize: none;
  height: 119px;
}

textarea.bolComment {
  resize: none;
  height: 119px;
  margin-bottom: 5px;
}

.orderMode textarea.bolComment {
  cursor: default;
}

.bolComment {
  overflow-x: hidden;
  overflow-y: auto;
  word-wrap: break-word;
}

.bol-link {
  margin-left: -8px;
}

.bol-icon {
  font-size: 1.3em;
  margin-right: 0.2em;
  position: absolute;
  top: 10%;
  left: 50%;
  height: 30%;
  width: 50%;
}

/* .bol-icon-text {
  position: absolute;
  top: 10%;
  left: 10%;
  height: 30%;
  width: 50%;
  margin-left: 20px;
} */
.selectedParty {
  background-color: #f1f1ff;
  min-height: 50px;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
  -webkit-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
  /* Firefox 3.5 - 3.6 */
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
  /*Chrome, IE*/
}

.recentCustomer, .recentLocation {
  vertical-align: top;
  min-height: 110px;
  background-color: #F6F6F6;
  border: solid 1px #0078AE;
  -webkit-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
  /* Firefox 3.5 - 3.6 */
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
  color: #0078AE;
  /*Chrome, IE*/
}

.recentCustomer:hover,
.recentCustomer.recentSelected,
.recentLocation:hover,
.recentLocation.recentSelected {
  background-color: #0078AE;
  color: white;
}

.recentCustomer label,
.recentLocation label {
  padding-left: 0;
}

.recentCustomers .recentsRow,
.recentLocations .recentsRow {
  padding-bottom: 10px;
}

.recentLocations {
  padding-right: 0;
}

.recentCustomers .recentsRow {
  padding-left: 16px;
}

.recentCustomer:focus,
.recentCustomer div:focus,
.recentLocation:focus,
.recentLocation div:focus {
  outline: none;
}

/* End Create Order*/
/* Create Order Items*/
.order-item-card {
  background-color: #f1f1ff;
  min-height: 50px;
  margin: 5px 5px 15px 5px;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
  /* Firefox 3.5 - 3.6 */
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
  /*Chrome, IE*/
}

.order-item-card label {
  margin-bottom: 3px;
  font-size: 11px;
}

.order-item-card .form-control {
  font-size: 11.5px;
}

.order-item-card .form-group {
  padding-left: 5px;
  padding-right: 5px;
}

#pickup-ref-nums,
#delivery-ref-nums,
.item-ref-nums {
  border: solid 1px #ddd;
  overflow-x: hidden;
  overflow-y: auto;
  height: 75px;
}

.order-item-card textarea {
  resize: none;
  height: 75px;
}

.order-item-card select.hasDefaultOption option:first-child,
.ref-num-edit-row select.hasDefaultOption option:first-child,
.default-option-selected {
  color: #adadad;
}

.default-option-selected > option {
  color: #363636;
}

.order-items {
  margin-left: 0;
  margin-right: 0;
}

.order-items .input-group-addon {
  font-size: 10px;
  padding: 5px 8px;
}

.order-item-card .remove-item {
  position: absolute;
  top: -8px;
  right: -10px;
  cursor: pointer;
}

.order-item-card .glyphicon-remove {
  color: #da0303;
  cursor: pointer;
}

.order-item-card .glyphicon-remove:hover {
  color: #772222;
}

.ref-num-row:nth-child(even) {
  background-color: #f9f9f9;
}

.item-summary {
  padding-top: 10px;
}

.item-summary-bar {
  background-color: #EAEAEA;
  padding: 5px;
  margin-top: -15px;
  margin-bottom: 10px;
}

.item-header-summary div,
.item-summary div {
  display: inline-block;
}

.item-header-summary .quantityTotal,
.item-header-summary .palletsTotal,
.item-summary .quantityTotal,
.item-summary .palletsTotal {
  min-width: 6em;
}

.item-header-summary .minWeightTotal,
.item-header-summary .lineItemsTotal,
.item-header-summary .maxWeightTotal,
.item-summary .minWeightTotal,
.item-summary .lineItemsTotal,
.item-summary .maxWeightTotal {
  min-width: 7em;
}

.item-header-summary .insuranceValueTotal,
.item-summary .insuranceValueTotal {
  min-width: 10em;
}

.item-header-summary strong,
.item-summary strong {
  padding-right: 10px;
}

.item-header-summary label,
.item-header-summary span,
.item-summary label,
.item-summary span {
  font-size: 11px;
  color: #777;
  padding-right: 5px;
}

.pad-ten {
  padding: 10px;
}

.items-placeholder {
  min-height: 60px;
}

.search-item-not-found,
.refine-search {
  min-height: 25px;
}

.search-item-not-found .help-block,
.refine-search .help-block {
  margin-bottom: 0;
}

.item-divider {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 5px;
}

.item-divider label {
  position: relative;
  display: inline-block;
}

.item-divider > label:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: #333;
  margin-left: 10px;
}

.uom-drop {
  /*100 clears placeholder shim for ie9*/
  z-index: 100;
}

.uom-drop .btn {
  text-transform: none;
  font-size: 13px;
  padding: 12px 10px;
}

.uom-drop.uom-drop-disabled .btn {
  cursor: default;
}

.uom-drop.uom-drop-disabled .btn:hover,
.uom-drop.uom-drop-disabled .btn:focus,
.uom-drop.uom-drop-disabled .btn:active {
  color: #0078ae;
  background-color: #ffffff;
  border-color: #0078ae;
  box-shadow: none;
  outline: none;
}

.owod-check, .navi-checkbox {
  font-size: 20px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owod-check-disabled, .navi-checkbox-disabled {
  font-size: 20px;
  color: grey;
  cursor: not-allowed;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.border-right-blue {
  border-right-color: #0078ae;
}

.no-border {
  border: none;
}

input[disabled].border-right-blue {
  border-right-color: #ccc;
}

.suggested-items-border {
  border-right-width: 1px;
  border-right-color: #ccc;
  border-right-style: solid;
}

/* End Create Order Items*/
/* Typeahead */
.dropdown-menu {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.dropdown-menu li {
  padding: 5px 10px;
}

.dropdown-menu > li.active {
  background-color: #428bca;
  color: #fff;
}

.dropdown-menu li span.item-name {
  font-weight: bold;
}

.dropdown-menu > li strong {
  color: #108de0;
}

/* End Typeahead */
/*accordion*/
.panel-group {
  margin-bottom: 20px;
  border-color: #ddd;
}

.panel-header-text-active {
  color: #108de0;
}

.panel-header-btn-link {
  margin-top: -2px;
}

.panel-header {
  background-color: #fff !important;
}

/*end accordion*/
/*fix to stop select.css from rounding border corners*/
.container .input-group > .ui-select-bootstrap > input.ui-select-search.form-control {
  border-radius: 0;
}

/*end fix to stop select.css from rounding border corners*/
/* angular ui bootstrap */
.nav, .pagination, .carousel, .panel-title a {
  cursor: pointer;
}

/* end angular ui bootstrap */
/* angualr ui date picker */
.datepicker-container {
  padding: 24px 0 0 0;
}

.date-time-row button {
  padding-left: 14px;
  padding-right: 14px;
}

.datepicker-container .input-group-btn:last-child > .btn, .datepicker-container .input-group-btn:last-child > .btn-group {
  margin-left: 0;
}

.datepicker-container .glyphicon-calendar {
  font-size: 11px;
}

.datepicker-container .text-info {
  color: rgb(244, 123, 32);
}

.datepicker-container th small {
  color: rgb(0, 120, 174);
}

.orange-icon {
  color: rgb(244, 123, 32);
  font-size: 16px;
}

.red-icon {
  color: #da0303;
}

.error-text {
  color: #FF0000;
}

/* UIB-DatePicker FIX for vertical alignment */
.date-time-row.uibfx table.uib-timepicker {
  margin-top: -24px;
}

/* end angular ui date picker */
.btn-sm-margin-bottom {
  margin-bottom: 4px;
}

/* required field clip */
.required-field-block {
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

.required-field-block .required-icon {
  display: inline-block;
  vertical-align: middle;
  border-color: #E8E8E8;
  padding: 0.5em 0.8em;
  color: rgba(0, 0, 0, 0.65);
  text-transform: uppercase;
  font-weight: normal;
  border-radius: 0.325em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 75%;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  margin: 0;
  width: 30px;
  height: 30px;
  padding: 0;
  text-align: center;
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  transition: color 0.2s ease;
  pointer-events: none;
}

.required-field-block.readonly .required-icon {
  border-color: #fff;
}

.required-field-block .required-icon:after {
  position: absolute;
  content: "";
  left: 1px;
  top: 1px;
  z-index: -1;
  width: 0;
  height: 0;
  border-top: 0 solid transparent;
  border-right: 0 solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 30px solid transparent;
  border-left-color: inherit;
  -webkit-transition: border-color 0.2s ease;
  -moz-transition: border-color 0.2s ease;
  transition: border-color 0.2s ease;
}

.required-field-block .required-icon .asterisk {
  color: #108de0;
  font-size: 22px;
  margin: -4px 0 0 -9px;
}

.required-field-block input,
.required-field-block select,
.required-field.block .ui-select-match,
.required-field-block .ui-select-toggle {
  padding-left: 20px;
}

.ui-select-match-text {
  width: 100%;
  overflow: hidden;
  display: inline-block;
  padding-right: 10px;
}

.ui-select-match-text span {
  overflow: hidden;
  width: 100%;
  display: inline-block;
}

.ui-select-match span {
  text-transform: none;
}

/* end required field clip */
.validation-message, .late-log {
  color: #da0303;
}

/* confirmation page */
.ok-glyp {
  color: #108de0;
  font-size: 170px;
}

.conf-num {
  font-weight: 900;
  font-size: larger;
}

.conf-links .glyphicon {
  color: #108de0;
  font-size: 50px;
}

.conf-links a {
  cursor: pointer;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  line-height: 50px;
  text-align: center;
}

.circle-background {
  background-color: #eee;
  padding-top: 25px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.conf-links > .col-xs-12 {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.conf-links-col {
  width: 20%;
}

#summaryCol .text-left {
  padding-left: 50px;
}

.conf-buttons .glyphicon-plus-sign {
  font-size: 16px;
}

#create-order-btn {
  padding-top: 11px;
  padding-bottom: 11px;
}

#create-order-btn,
#view-bol-btn,
#share-tracking-btn {
  margin: 0 3px;
  margin-bottom: 5px;
}

/* end confirmation page */
/*order details*/
.sml-lbl,
.sml-txt {
  font-size: smaller;
}

.order-details-txt {
  font-weight: normal;
  font-size: 11px;
}
.order-details-txt .icon-link-text.sml-txt {
  font-size: smaller;
}

.order-details-lbl {
  font-weight: 600;
  font-size: 11px;
}

.itemDetails tr.details-row:hover {
  background-color: #fff;
}

.add-item-ref-btn {
  padding-bottom: 10px;
  padding-left: 15px;
}

.comm-desc-col {
  max-width: 350px;
  overflow-x: hidden;
}

.item-detail-notes {
  overflow-x: hidden;
  overflow-y: auto;
  word-wrap: break-word;
  max-width: 270px;
}

.carrier-log-list {
  max-height: 175px;
  overflow-x: auto;
}

/*end order details*/
/*order details service offering*/
.service-offering-icon,
.shipping-item-icon {
  width: 50px;
  height: 50px;
}

.service-offering-icon path {
  fill: red;
}

.reference-numbers-label {
  padding-left: 0;
}

.remove-notification .glyphicon-remove-sign,
.ref-num-edit-row .glyphicon-remove-sign {
  font-size: 20px;
  color: #da0303;
  cursor: pointer;
}

.remove-notification .glyphicon-remove-sign:hover,
.order-item-card .glyphicon-remove-sign:hover,
.ref-num-edit-row .glyphicon-remove-sign:hover {
  color: #772222;
  font-size: 19px;
  margin-top: 1px;
  margin-left: 1px;
}

.order-item-card .glyphicon-remove-sign {
  font-size: 20px;
  color: #da0303;
  cursor: pointer;
  margin-right: 5px;
}

.ref-num-edit-row {
  padding-bottom: 10px;
  height: 50px;
}

.remove-col {
  text-align: left;
  padding-left: 0;
  padding-top: 8px;
}

.ref-num-edit-row .sml-lbl {
  padding-top: 10px;
}

.ref-num-edit-row .form-control {
  font-size: 11px;
}

/*end order details service offering*/
/*order details pickupFrom*/
.spcl-inst {
  max-height: 146px;
  overflow-x: hidden;
  overflow-y: auto;
  word-wrap: break-word;
}

.loc-edit-ref-nums {
  /*padding-top: 24px;*/
}

.itemDetails {
  padding: 0 50px;
}

.itemDetails .item-summary {
  padding-top: 18px;
}

.itemDetails th {
  color: #108de0;
}

.itemDetails .lineItemsTotal {
  min-width: 110px;
  padding-right: 20px;
}

.itemDetails .quantityTotal {
  min-width: 70px;
  padding-right: 20px;
}

.itemDetails .minWeightTotal {
  min-width: 120px;
  padding-right: 20px;
}

.itemDetails .insuranceValueTotal {
  min-width: 160px;
  padding-right: 20px;
}

.itemDetails .palletsTotal {
  min-width: 90px;
  padding-right: 20px;
}

.details-row span,
.details-row strong {
  font-size: smaller;
}

/*end order details pickupFrom*/
/*start my orders*/
.myorder-sortby-row {
  margin: 0 5px;
}

.myorder-row {
  margin: 5px 5px 15px 5px;
  padding-top: 10px;
  padding-right: 40px;
  -webkit-box-shadow: 0px 2px 5px #676767;
  -moz-box-shadow: 0px 2px 5px #676767;
  box-shadow: 0px 2px 5px #676767;
  box-shadow: 0px 2px 5px #676767;
  background: #fff;
}

.myorder-header-text {
  color: #838383;
}

.myorder-semibold-opensans {
  font-weight: 600;
}

.myorder-noresultsfound {
  margin: 5px;
  margin-top: 21px;
}

.myorder-status {
  font-size: 11px;
}

.myorder-hazmat {
  font-size: 8px;
  margin-left: 2px;
  margin-bottom: 5px;
}

.myorders-filters {
  padding-left: 10px;
}

.myorders-filters-content {
  vertical-align: middle;
  font-size: 12px;
}

.myorders-filters-icon {
  vertical-align: middle;
  margin-left: 10px;
  font-size: 12px;
}

#myorderHeader-container,
#quoteHeader-container {
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 82px;
  z-index: 500;
  -webkit-box-shadow: 2px 2px 5px #cccccc;
  -ms-box-shadow: 2px 2px 5px #cccccc;
  box-shadow: 2px 2px 5px #cccccc;
}

.results-per-page {
  float: right;
  width: 200px;
  margin: 20px 0;
  line-height: 30px;
}

.results-per-page select {
  min-width: 70px;
}

.results-per-page-buttons {
  margin: 20px 0;
  width: 250px;
}

.results-per-page-buttons select {
  min-width: 70px;
}

.excel-export .excel-export-image {
  height: 30px;
  margin: 20px 0;
}

.results-per-page span,
.results-per-page option,
.results-per-page select {
  font-size: 12px;
  height: initial;
  border-color: #ddd;
}

.results-per-page span {
  color: #777;
}

.myorder-container .pagination > li > a {
  font-size: 13px;
}

/*end my orders*/
/*bootstrap table mod*/
.table {
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
  background-color: transparent;
}

.thead {
  display: table-header-group;
}

.tbody {
  display: table-row-group;
}

.tr {
  display: table-row;
}

.td,
.th {
  display: table-cell;
}

.tfoot {
  display: table-footer-group;
}

.table > .thead > .tr > .th,
.table > .tbody > .tr > .th,
.table > .tfoot > .tr > .th,
.table > .thead > .tr > .td,
.table > .tbody > .tr > .td,
.table > .tfoot > .tr > .td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.table > .thead > .tr > .th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

/*.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,*/
.table > .thead:first-child > .tr:first-child > th,
.table > .thead:first-child > .tr:first-child > td {
  border-top: 0;
}

.table > .tbody + .tbody {
  border-top: 2px solid #ddd;
}

.table .table {
  background-color: #fff;
}

.table-condensed > .thead > .tr > .th,
.table-condensed > .tbody > .tr > .th,
.table-condensed > .tfoot > .tr > .th,
.table-condensed > .thead > .tr > .td,
.table-condensed > .tbody > .tr > .td,
.table-condensed > .tfoot > .tr > .td {
  padding: 5px;
}

.table-bordered {
  border: 1px solid #ddd;
}

.table-bordered > .thead > .tr > .th,
.table-bordered > .tbody > .tr > .th,
.table-bordered > .tfoot > .tr > .th,
.table-bordered > .thead > .tr > .td,
.table-bordered > .tbody > .tr > .td,
.table-bordered > .tfoot > .tr > .td {
  border: 1px solid #ddd;
}

.table-bordered > .thead > .tr > .th,
.table-bordered > .thead > .tr > .td {
  border-bottom-width: 2px;
}

.table-striped > .tbody > .tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-hover > .tbody > .tr:hover {
  background-color: #f5f5f5;
}

.table .td[class*=col-],
.table .th[class*=col-] {
  position: static;
  display: table-cell;
  float: none;
}

.table > .thead > .tr > .td.active,
.table > .tbody > .tr > .td.active,
.table > .tfoot > .tr > .td.active,
.table > .thead > .tr > .th.active,
.table > .tbody > .tr > .th.active,
.table > .tfoot > .tr > .th.active,
.table > .thead > .tr.active > .td,
.table > .tbody > .tr.active > .td,
.table > .tfoot > .tr.active > .td,
.table > .thead > .tr.active > .th,
.table > .tbody > .tr.active > .th,
.table > .tfoot > .tr.active > .th {
  background-color: #f5f5f5;
}

.table-hover > .tbody > .tr > .td.active:hover,
.table-hover > .tbody > .tr > .th.active:hover,
.table-hover > .tbody > .tr.active:hover > .td,
.table-hover > .tbody > .tr:hover > .active,
.table-hover > .tbody > .tr.active:hover > .th {
  background-color: #e8e8e8;
}

.table > .thead > .tr > .td.success,
.table > .tbody > .tr > .td.success,
.table > .tfoot > .tr > .td.success,
.table > .thead > .tr > .th.success,
.table > .tbody > .tr > .th.success,
.table > .tfoot > .tr > .th.success,
.table > .thead > .tr.success > td,
.table > .tbody > .tr.success > td,
.table > .tfoot > .tr.success > td,
.table > .thead > .tr.success > th,
.table > .tbody > .tr.success > th,
.table > .tfoot > .tr.success > th {
  background-color: #dff0d8;
}

.table-hover > .tbody > .tr > .td.success:hover,
.table-hover > .tbody > .tr > .th.success:hover,
.table-hover > .tbody > .tr.success:hover > .td,
.table-hover > .tbody > .tr:hover > .success,
.table-hover > .tbody > .tr.success:hover > .th {
  background-color: #d0e9c6;
}

.table > .thead > .tr > .td.info,
.table > .tbody > .tr > .td.info,
.table > .tfoot > .tr > .td.info,
.table > .thead > .tr > .th.info,
.table > .tbody > .tr > .th.info,
.table > .tfoot > .tr > .th.info,
.table > .thead > .tr.info > .td,
.table > .tbody > .tr.info > .td,
.table > .tfoot > .tr.info > .td,
.table > .thead > .tr.info > .th,
.table > .tbody > .tr.info > .th,
.table > .tfoot > .tr.info > .th {
  background-color: #d9edf7;
}

.table-hover > .tbody > .tr > .td.info:hover,
.table-hover > .tbody > .tr > .th.info:hover,
.table-hover > .tbody > .tr.info:hover > .td,
.table-hover > .tbody > .tr:hover > .info,
.table-hover > .tbody > .tr.info:hover > .th {
  background-color: #c4e3f3;
}

.table > .thead > .tr > .td.warning,
.table > .tbody > .tr > .td.warning,
.table > .tfoot > .tr > .td.warning,
.table > .thead > .tr > .th.warning,
.table > .tbody > .tr > .th.warning,
.table > .tfoot > .tr > .th.warning,
.table > .thead > .tr.warning > .td,
.table > .tbody > .tr.warning > .td,
.table > .tfoot > .tr.warning > .td,
.table > .thead > .tr.warning > .th,
.table > .tbody > .tr.warning > .th,
.table > .tfoot > .tr.warning > .th {
  background-color: #fcf8e3;
}

.table-hover > .tbody > .tr > .td.warning:hover,
.table-hover > .tbody > .tr > .th.warning:hover,
.table-hover > .tbody > .tr.warning:hover > .td,
.table-hover > .tbody > .tr:hover > .warning,
.table-hover > .tbody > .tr.warning:hover > .th {
  background-color: #faf2cc;
}

.table > .thead > .tr > .td.danger,
.table > .tbody > .tr > .td.danger,
.table > .tfoot > .tr > .td.danger,
.table > .thead > .tr > .th.danger,
.table > .tbody > .tr > .th.danger,
.table > .tfoot > .tr > .th.danger,
.table > .thead > .tr.danger > .td,
.table > .tbody > .tr.danger > .td,
.table > .tfoot > .tr.danger > .td,
.table > .thead > .tr.danger > .th,
.table > .tbody > .tr.danger > .th,
.table > .tfoot > .tr.danger > .th {
  background-color: #f2dede;
}

.table-hover > .tbody > .tr > .td.danger:hover,
.table-hover > .tbody > .tr > .th.danger:hover,
.table-hover > .tbody > .tr.danger:hover > .td,
.table-hover > .tbody > .tr:hover > .danger,
.table-hover > .tbody > .tr.danger:hover > .th {
  background-color: #ebcccc;
}

.navi-glyph {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "navi-glyphs", serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navi-glyph-commodity:before {
  content: "a";
}

.med-icon {
  font-size: 35px;
  color: #108de0;
}

.circle-background-sml {
  background-color: #eee;
  padding-top: 7px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

#filters {
  margin: 15px 0 0 0;
  padding: 0 10px;
}

#filters ul {
  list-style: none;
}

#filters ul li {
  line-height: 32px;
  margin-left: -40px;
}

#filters .filtername {
  min-height: 26px;
  display: block;
  position: relative;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 26px;
  color: #616363;
  font-weight: bold;
  padding: 0 30px 0 0;
}

#filters ul li a {
  display: inline-block;
  font-size: 13px;
  line-height: 16px;
  color: #363738;
  text-decoration: none;
  padding: 10px 0 10px 15px;
  vertical-align: middle;
  width: 100%;
}

#myOrders-search-box {
  margin: 0;
  margin-top: 10px;
  padding: 0;
}

#myOrders-search-box .search-query {
  padding-right: 4px;
  padding-left: 4px;
  margin-bottom: 0;
  border-radius: 3px;
}

.myOrders-search-box-button {
  border: 0;
  background: none;
  margin-top: 2px;
  position: relative;
  left: 8px;
  margin-bottom: 0;
  border-radius: 3px;
  color: #838383;
}

.header-search-box-button {
  border: 0;
  background: none;
  margin-top: 2px;
  position: relative;
  left: 8px;
  margin-bottom: 0;
  border-radius: 3px;
  color: #fff;
}

.header-search-box-button:hover {
  color: #108de0;
}

.header-search .header-search-box-button {
  line-height: 26px;
}

.myOrders-search-box-clear-button {
  border: 0;
  background: none;
  margin-left: -12px;
  position: relative;
  left: 8px;
  margin-bottom: 0;
  border-radius: 3px;
  color: #838383;
}

#refNumberSearch {
  float: left;
  width: 150px;
  height: 29px;
  line-height: 27px;
  /*text-indent:10px;*/
  font-family: arial, sans-serif;
  font-size: 12px;
  color: #333;
  background: #fff;
  border: solid 1px #d9d9d9;
  border-top: solid 1px #c0c0c0;
  border-right: none;
}

#refNumberSearch:focus {
  box-shadow: none;
}

#refNumberSearch::-ms-clear {
  display: none;
}

input[type=text]::-ms-clear {
  display: none;
}

#delete {
  float: left;
  width: 16px;
  height: 29px;
  line-height: 27px;
  font-size: 12px;
  background: #fff;
  border: solid 1px #d9d9d9;
  border-top: solid 1px #c0c0c0;
  border-left: none;
}

/* Set default state of "X" and hide it */
#delete #x {
  color: #d9d9d9;
  cursor: pointer;
}

/* Set the hover state of "X" */
#delete #x:hover {
  color: #36c;
}

/* doc manager */
.upload-doc-form .control-label {
  text-align: left;
}

.doc-actions {
  margin-bottom: 5px;
}

.doc-upload {
  margin: 5px;
  padding-top: 5px;
  border: 1px solid rgb(221, 221, 221);
  -ms-border-radius: 1px;
  border-radius: 5px;
}

.doc-action {
  font-size: 14px;
  margin: 5px;
  cursor: pointer;
}

.doc-title {
  font-size: 15px;
  font-weight: bold;
  vertical-align: middle;
}

.doc-title svg {
  padding: 4px;
  margin-left: -4px;
}

.doc-link {
  cursor: pointer;
}

.doc-mgr-actions-col .doc-mgr-checkbox {
  color: #27608c;
  outline: 0;
  font-size: 22px;
  vertical-align: inherit;
}

th.doc-mgr-actions-col .doc-mgr-checkbox {
  padding-left: 22px;
}

/* spinning stuff */
.spinning {
  -webkit-animation: spin1 0.8s infinite linear;
  -moz-animation: spin1 0.8s infinite linear;
  -o-animation: spin1 0.8s infinite linear;
  -ms-animation: spin1 0.8s infinite linear;
  animation: spin1 0.8s infinite linear;
}

@-webkit-keyframes spin1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-moz-keyframes spin1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-o-keyframes spin1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-ms-keyframes spin1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
@keyframes spin1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
#documentViewerRefresh:focus,
#carrier-logs-expander:focus {
  outline: 0 auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.progress-bar-container {
  padding: 5px 110px 5px 110px;
  height: 40px;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (doesn't work very well) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 100%;
}

.progress-bar-container-surround {
  padding: 5px;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (doesn't work very well) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 100%;
}

.ordered {
  -ms-flex: 1;
  flex: 1;
  display: inline-block;
}

.booked {
  -ms-flex: 1;
  flex: 1;
  display: inline-block;
}

.in-transit {
  -ms-flex: 1;
  flex: 1;
  display: inline-block;
}

.delivered {
  -ms-flex: 1;
  flex: 1;
  display: inline-block;
}

.status-subdata {
  text-align: center;
  display: inline-block;
  width: 100%;
}

.ball {
  background-color: #838383;
  border: solid 3px #838383;
  -webkit-border-radius: 1000px;
  -moz-border-radius: 1000px;
  border-radius: 1000px;
  z-index: 1;
  -ms-flex: 1;
  flex: 1;
  display: inline-block;
}

.bar {
  background-color: #838383;
  height: 10px;
  border: solid 3px #838383;
  margin: 11px -3px 0;
  z-index: 0;
  -ms-flex: 8;
  flex: 8;
  display: inline-block;
}

.background-red {
  background-color: #da0303;
}

.background-green {
  background-color: #729a14;
}

.background-orange {
  background-color: #108de0;
}

.background-grey {
  background-color: #F0F0F0;
}

.background-white {
  background-color: #ffffff;
}

.background-solid-black {
  background-color: #000;
}

.background-transparent-black {
  background: rgba(0, 0, 0, 0.7);
}

.text-grey {
  color: #363636;
}

.text-red {
  color: #da0303;
}

.text-white {
  color: #fff;
}

.text-green {
  color: #729a14;
}

.text-orange {
  color: #108de0;
}

/* GAQ */
.gaq.filters.panel .btn {
  margin-top: 5px;
  display: table;
  min-width: 100px;
}

.dimensions div {
  padding: 0;
  padding-right: 5px;
}

.dimensions label {
  display: block;
}

.gaq-widget .datepicker-container {
  margin: 0;
  padding: 0;
}

.gaq-widget .row {
  margin: 0;
  padding: 0 0 20px 0;
}

.gaq-results-table {
  box-sizing: border-box;
  padding-top: 30px;
}

.gaq-results-table .error {
  color: #da0303;
  font-weight: bold;
  margin-top: 10px;
}

.gaq-result,
.gaq-header {
  border-bottom: 1px solid #999;
  display: inline-block;
  width: 100%;
}

.gaq-header {
  border-bottom: 2px solid #999;
}

.gaq-header div {
  font-weight: bold;
  font-size: 14px;
}

.gaq-header-titles > div,
.relative {
  position: relative;
}

.gaq-header-titles > div > span {
  position: absolute;
  bottom: 0;
  left: 15px;
}

.gaq-header-titles .estimated-charges {
  left: 0;
}

.breakdown div {
  padding: 0;
}

.gaq-result .breakdown .totals {
  font-weight: bold;
}

.breakdown .price {
  padding-right: 5px;
}

.gaq-result span {
  display: block;
  font-weight: normal;
}

.gaq-result:focus {
  outline: none;
}

.gaq-result .breakdown svg {
  width: 16px;
  height: 16px;
}

.breakdown .chevron {
  visibility: hidden;
}

.breakdown .row:first-of-type .chevron {
  visibility: visible;
}

.gaq .legal {
  margin-top: 60px;
}

.gaq .legal p {
  font-size: 10px;
}

.gaq .legal span {
  text-transform: uppercase;
}

.pad-left-0 {
  padding-left: 0;
}

/* GAQ -- NEW v2.0 */
.address-book {
  width: 100%;
  text-align: right;
}

.btn-calendar {
  font-size: 18px;
  padding: 10px 20px;
}

.quote-container .gaq .panel {
  padding: 20px;
  display: inline-block;
  width: 100%;
}

.quote-container .gaq .panel h2 {
  color: #0078ae;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.checkbox-list {
  list-style: none;
  padding-left: 5px;
}

.checkbox-list li a {
  display: inline-block;
  font-size: 13px;
  line-height: 16px;
  color: #363738;
  text-decoration: none;
  padding: 3px 0 3px 10px;
  vertical-align: middle;
}

.icon-link-text {
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.icon-link-text:hover {
  text-decoration: none;
}

.checkbox-list.horizontal li {
  display: inline-block;
  padding-left: 20px;
  float: left;
}

.icon-link-text.disabled {
  color: #999;
  cursor: not-allowed;
}

/* GAQ RESULTS PAGE -- NEW v2.0 */
.carrier-name span {
  font-weight: normal;
}

.quote-summary .icon-link-text {
  font-size: 16px;
}

.gaq.results .data {
  font-size: 16px;
  padding-right: 10px;
}

.commodity {
  position: relative;
}

.commodity .glyphicon-remove {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #da0303;
}

.bucket {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.bucket.highlight,
.bucket:hover {
  position: relative;
  border-bottom: 5px solid #0078ae;
}

.gaq-results-actions {
  margin-bottom: 10px;
}

.gaq-results-actions a {
  margin-right: 30px;
  cursor: pointer;
}

.gaq-results-actions a:hover {
  text-decoration: none;
}

.triangle-on {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #0077ae transparent transparent transparent;
  position: absolute;
  left: 45%;
  bottom: -10px;
}

.triangle-off {
  border: none;
}

.quote-container .gaq .gaq-results-card {
  padding: 0px;
}

.gaq-results-card .flex-row {
  position: relative;
  height: auto;
}

.details-box {
  width: 75%;
  padding: 20px;
  background-color: #fff;
  position: relative;
  z-index: 1;
}

.charges-box {
  width: 25%;
  padding: 20px;
  background-color: #F0F0F0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  color: #0078ae;
  text-transform: uppercase;
  position: relative;
}

.charges-box .currency-abbr {
  font-size: 12px;
  margin: 8px 0 0 5px;
}

.date-recommender-quote-label {
  position: absolute;
  top: 0;
  text-align: center;
  margin-top: 15px;
  font-size: 16px;
  text-transform: none;
}

.share-actions {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px;
  font-weight: normal;
  height: 24px;
  width: 100%;
  padding: 0 10px;
}

.share-actions .share-label {
  color: #666;
  vertical-align: middle;
  font-weight: bold;
}

.share-actions .share-label a:hover,
.share-actions .glyphicon-question-sign:hover {
  text-decoration: none;
  color: #005c85;
}

.share-actions a svg:hover {
  fill: #005c85;
}

.carrier-name {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
}

.cargo-liability {
  padding-top: 10px;
  height: 20px;
}

.ltl-quote {
  padding-top: 10px;
  height: 20px;
}

.carrier-mode {
  font-size: 14px;
  display: inline-block;
  float: left;
  width: 25%;
  margin-top: -25px;
}

.carrier-mode span {
  display: block;
  text-transform: uppercase;
  margin-top: -10px;
}

.transit-time {
  display: inline-block;
  float: left;
  width: 36%;
  font-size: 18px;
}

.transit-time span {
  display: block;
  font-size: 14px;
}

.delivery-date {
  display: inline-block;
  float: left;
  width: 36%;
  font-size: 18px;
}

.delivery-date span {
  display: block;
  font-size: 14px;
}

.ship-this {
  display: inline-block;
  float: right;
  text-align: center;
}

/* GAQ CHARGES BREAKDOWN SLIDEOUT BOX THINGY */
.charges-breakdown-link {
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  bottom: 3px;
  right: 5px;
}

.charges-breakdown-box span {
  vertical-align: top;
}

.charges-breakdown-box {
  position: absolute;
  background-color: #676767;
  color: #fff;
  font-size: 14px;
  padding: 30px 10px 5px 10px;
  overflow: hidden;
  overflow-y: auto;
  width: 400px;
  height: 175px;
  top: 0;
  left: 320px;
  transition-duration: 0.3s;
}

.price-total {
  text-align: center;
}

.new-currency {
  display: inline-block;
}

.new-currency .currency-abbr {
  text-align: left;
  line-height: 11px;
  width: 25px;
}

.show-addl-charges {
  white-space: nowrap;
  color: #f47b20;
  display: block;
}

.charges-breakdown-box.open {
  top: 0;
  left: 75%;
}

.charges-breakdown-box .glyphicon-remove {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.charges-breakdown-box hr {
  margin: 5px 0;
}

.charges-breakdown-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.charges-breakdown-box .charge-name {
  display: inline-block;
  width: 65%;
}

.charges-breakdown-box .charge-amount {
  display: inline-block;
  width: 35%;
  text-align: right;
}

.charges-breakdown-box .total {
  font-weight: bold;
}

/* GAQ FILTERS BOX */
.gaq.results {
  position: relative;
}

.gaq.results.left-filters {
  margin-left: 25%;
}

.quote-container .side-box {
  position: absolute;
  left: -240px;
  top: 0;
  width: 220px;
  padding-bottom: 10px;
}

.quote-container .sortFilters {
  padding-bottom: 20px;
}

.gaq.filters label {
  padding-left: 5px;
  margin: 0;
}

.gaq.filters ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.gaq.filters ul li {
  padding-left: 10px;
}

.gaq.filters ul li a {
  color: #333;
  text-decoration: none;
}

.gaq.filters .accessorials,
.gaq.filters .modes,
.gaq.filters .carriers {
  margin-bottom: 20px;
}

/* GAQ Quote To Order */
.quote-to-order .datepicker-container {
  padding: 0;
}

/* =============== */
/* == SVG ICONS == */
/* =============== */
svg.icon {
  color: transparent;
  fill: #000;
  height: 128px;
  width: 128px;
  vertical-align: middle;
}

a svg {
  cursor: pointer;
}

svg.make-smaller {
  padding: 4px;
  margin: -2px;
}

/* = ICON SIZES =*/
.icon.xxxs {
  width: 24px;
  height: 24px;
}

.icon.xxs {
  width: 30px;
  height: 30px;
}

.icon.xs {
  width: 40px;
  height: 40px;
}

.icon.sm {
  width: 50px;
  height: 50px;
}

.icon.md {
  width: 60px;
  height: 60px;
}

.icon.lg {
  width: 90px;
  height: 90px;
}

.icon.xl {
  width: 120px;
  height: 120px;
}

.icon.xxl {
  width: 160px;
  height: 160px;
}

.icon.full-width {
  width: 100%;
  max-height: 80px;
}

/* = ICON COLORS =*/
.icon.primary {
  color: transparent;
  fill: #108de0;
}

.icon.secondary {
  color: transparent;
  fill: #838383;
}

.icon.white {
  color: transparent;
  fill: white;
}

.icon.alert {
  color: transparent;
  fill: #da0303;
}

.icon.dark-blue {
  color: transparent;
  fill: #27608c;
}

.icon.blue {
  color: transparent;
  fill: #0078ae;
}

.icon.success {
  color: transparent;
  fill: #7b9f3e;
}

.icon.grayed-out {
  color: transparent;
  fill: #ccc;
}

a:hover .icon.blue {
  fill: #005c85;
}

/* = ICON COLORS W/ CIRCLES =*/
.icon.primary.circle {
  color: #108de0;
  fill: #fff;
}

.icon.secondary.circle {
  color: #838383;
  fill: #fff;
}

.icon.alert.circle {
  color: #da0303;
  fill: #fff;
}

.icon.error.circle {
  color: #da0303;
  fill: #fff;
}

.icon.blue.circle {
  color: #0078AE;
  fill: #fff;
}

.icon.white.circle {
  color: #fff;
  fill: #0078AE;
}

.icon.dark-blue.circle {
  color: #27608c;
  fill: #fff;
}

.icon.light-blue.circle {
  color: #f1f1ff;
  fill: #108de0;
}

.icon.success.circle {
  color: #7b9f3e;
  fill: #fff;
}

/* = ICON HOVER =*/
.icon.primary.interactive:hover {
  color: transparent;
  fill: #e06000;
}

.icon.secondary.interactive:hover {
  color: transparent;
  fill: #108de0;
}

.icon.alert.interactive:hover {
  color: transparent;
  fill: #d00;
}

.icon.dark-blue.interactive:hover {
  color: transparent;
  fill: #0078AE;
}

/* = ICON HOVERS W/ CIRCLES =*/
.icon.primary.circle.interactive:hover {
  color: #e06000;
  fill: #fff;
}

.icon.secondary.circle.interactive:hover {
  color: #999;
  fill: #fff;
}

.icon.alert.circle.interactive:hover {
  color: #d00;
  fill: #fff;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.orange-circle {
  background-color: #108de0;
  width: 90px;
  height: 90px;
  border-radius: 90px;
  line-height: 90px;
  text-align: center;
}

.orange-circle > svg {
  width: 80px;
  height: 80px;
}

.orange-circle-sm {
  background-color: #108de0;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  line-height: 50px;
  text-align: center;
}

.orange-circle-sm > svg {
  width: 45px;
  height: 45px;
}

.service-analytics-report .report-dates {
  font-size: 16px;
  margin-top: -10px;
  margin-bottom: 20px;
}

.service-analytics-report h2.sub-title {
  margin-bottom: 0;
  font-size: 28px;
  font-weight: bold;
}

/*HELPERS*/
.flex-row {
  display: flex;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

.clear-both {
  clear: both;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-0 {
  padding: 0;
}

.uppercase {
  text-transform: uppercase;
}

/* FILE AND SORT */
.service-offering svg {
  margin-bottom: -20px;
}

.order-details-service-icon svg {
  margin-bottom: -8px;
}

.order-details-service-icon span.sml-txt {
  display: block;
}

.modal-dialog label.control-label {
  text-transform: uppercase;
}

/* NEW GLOBAL */
/* ACCOUNTS PAYABLE -- GLOBAL */
.panel-reset {
  padding: 20px;
}

.panel-reset .row {
  margin-top: 20px;
}

.panel-reset h1,
.panel-reset h2 {
  margin: 0;
}

.panel-reset h2 {
  margin-top: 10px;
  color: #108de0;
}

#accounts-payable-results thead th,
#accounts-payable-results tbody th,
#accounts-payable-results tfoot th,
#accounts-payable-results thead td,
#accounts-payable-results tbody td,
#accounts-payable-results tfoot td {
  padding: 8px 5px;
}

/* ACCOUNTS PAYABLE -- SEARCH FOR INVOICES */
#search-invoices .datepicker-container {
  padding-top: 0;
}

#search-invoices .btn-primary {
  margin-top: 20px;
}

/* ACCOUNTS PAYABLE -- ACCOUNT SUMMARY */
#account-summary h2 {
  margin-top: 0;
}

#account-summary h4 {
  margin: 0 0 10px 10px;
}

#account-summary .panel-default {
  border: 1px solid #ddd;
}

.buckets-row {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
}

.buckets-item {
  background: #fefefe;
  padding: 0;
  width: 175px;
  margin: 0;
  font-weight: bold;
  font-size: 3em;
  text-align: center;
  position: relative;
  border: 2px solid #000;
}

.buckets-item.active {
  background-color: #ccc;
}

.buckets-item:hover {
  background-color: #0078ae;
}

.buckets-item .bucket-title {
  color: #fff;
  font-size: 16px;
  width: 100%;
  background-color: #000;
  padding: 5px 0;
  font-weight: normal;
}

.buckets-item-row {
  padding: 5px 0;
  display: flex;
}

.buckets-item .bucket-amount {
  color: #108de0;
  font-weight: bold;
  font-size: 18px;
  width: 70%;
  float: left;
  text-align: right;
  line-height: 20px;
  vertical-align: middle;
}

.buckets-item .bucket-currency {
  font-weight: normal;
  color: #000;
  font-size: 14px;
  width: 30%;
  float: left;
  text-align: left;
  text-transform: uppercase;
  padding-left: 5px;
  vertical-align: middle;
  line-height: 20px;
}

.results-bar {
  margin: 20px 0;
  width: auto;
}

.results-bar .pagination {
  margin: 0;
  vertical-align: top;
}

.results-bar .excel-export {
  float: right;
}

#accounts-payable-results {
  margin-bottom: 0;
}

#accounts-payable-results thead {
  background-color: #fff;
  color: #108de0;
  font-size: 12px;
}

#accounts-payable-results thead .glyphicon {
  color: #ada9a9;
}

#accounts-payable-results thead .glyphicon.active {
  color: #676767;
}

#accounts-payable-results > tbody > tr:nth-of-type(odd) {
  background-color: #e8f0f4;
}

#accounts-payable-results .primary-ref-numbers {
  max-width: 130px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

#accounts-payable-results .check-numbers {
  max-width: 65px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

#accounts-payable-results .monetary-value {
  text-align: right;
}

/* ORDER DETAILS -- CHARGES */
.chargesDetails .excel-export {
  position: absolute;
  top: -25px;
  right: -50px;
}

.chargesDetails .charges-totals {
  background-color: #e8f0f4;
  font-weight: bold;
}

/* DRAW PO -- SEARCH & DRAW SCREEN */
#draw-po .panel-reset {
  margin-bottom: 20px;
}

#draw-po .radio {
  margin: 0;
}

#draw-po input[type=radio] + label {
  padding: 0;
  line-height: 24px;
}

input[type=radio] + label::before {
  font-size: 20px;
}

.po-item-modal .modal-footer {
  padding: 15px 0 0 0;
}

#draw-po .datepicker-container {
  padding-top: 0;
}

#draw-po .btn-primary {
  margin-top: 22px;
}

#draw-po .btn-clear {
  width: 35%;
  padding: 12px 20px;
  float: left;
}

#draw-po .btn-search {
  width: 60%;
  float: right;
}

.modal-footer .po-total-items {
  float: left;
  line-height: 40px;
}

#draw-po .po-draw-actions-buttons {
  text-align: right;
}

#draw-po .po-draw-actions-buttons .btn {
  height: 44px;
  width: auto;
}

#draw-po .po-draw-actions-buttons .btn-gray {
  background-color: #CACACA;
  border-color: #BBB;
}

#draw-po .po-draw-actions-buttons .btn-gray:hover {
  background-color: #999;
  color: #fff;
}

#draw-po .action-button .btn {
  width: 100%;
}

.po-search-results-title {
  background: #676767;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  padding: 4px 20px;
}

.po-search-results .myorder-row {
  padding: 5px;
  font-size: 10px;
}

.commodity-name {
  font-weight: bold;
  font-size: 12px;
}

#draw-po .action-button button {
  font-size: 11px;
  line-height: 10px;
  vertical-align: middle;
  text-transform: uppercase;
  padding: 8px 2px;
  margin: 0 0 5px 0;
  font-weight: bold;
}

#po-draw-modal .uib-time input {
  font-size: 14px;
  height: 40px;
}

.action-button-small button {
  font-size: 13px;
  line-height: 13px;
  vertical-align: middle;
  text-transform: uppercase;
  padding: 10px 10px;
  margin-top: 12px;
}

.action-button button.update-qty {
  background-color: #fff;
  color: #0078ae;
}

.action-button button.update-qty:hover {
  background-color: #0078ae;
  color: #fff;
}

.draw-qty input {
  width: 46px;
  height: 18px;
  display: inline-block;
  padding: 0 0 0 2px;
  font-size: 10px;
}

.remove-from-order {
  text-align: center;
}

.remove-from-order a {
  text-transform: uppercase;
  text-decoration: underline;
  text-align: center;
  font-size: 8px;
  font-weight: bold;
}

/* DRAW PO -- MODAL */
#po-draw-modal .modal-header h3 {
  display: inline-block;
  float: left;
  line-height: 30px;
}

#po-draw-modal .modal-header h3 {
  display: inline-block;
  float: left;
}

.po-draw-step-counter {
  float: right;
}

.po-draw-step-counter .po-step {
  float: left;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  display: block;
  height: 30px;
  width: 30px;
  line-height: 24px;
  -moz-border-radius: 15px; /* or 50% */
  border-radius: 15px; /* or 50% */
  background-color: #0078ae;
  text-align: center;
  border: 3px solid #fff;
  margin: 0 3px;
}

.po-draw-step-counter .po-step.in-progress {
  color: #0078ae;
  background-color: #fff;
}

.po-draw-step-counter .po-step.complete {
  color: #0078ae;
  background-color: #ccc;
}

#po-draw-summary td .form-group {
  margin-bottom: 0;
}

#po-draw-modal table input,
#po-draw-modal table select {
  width: 100%;
  height: 18px;
  display: inline-block;
  padding: 0 0 0 2px;
  font-size: 10px;
}

#po-draw-modal table .delete {
  width: 30px;
}

#po-draw-modal table .item-desc {
  text-align: left;
  max-width: 170px;
}

#po-draw-modal table .draw-qty {
  width: 75px;
}

#po-draw-modal table .packaging-type {
  width: 105px;
}

#po-draw-modal table .inner-pack-qty {
  width: 105px;
}

#po-draw-modal table .weight {
  width: 85px;
}

#po-draw-modal table .pallets {
  width: 60px;
}

#po-draw-modal table .pallet-spaces {
  width: 95px;
}

#po-draw-modal table .shipping-class {
  width: 70px;
}

#po-draw-modal table .trailer-ft {
  width: 70px;
}

#po-draw-modal .distribute-totals {
  background-color: #fcfcfc;
}

#po-draw-modal .distribute-totals td {
  padding: 10px 8px;
  line-height: 25px;
}

#po-draw-modal .distribute-totals .btn-sm {
  margin: 0;
  text-transform: uppercase;
  font-size: 12px;
}

.po-draw-step2 .location-select {
  min-height: 205px;
}

.pickup-side {
  border-right: 1px solid #676767;
}

.po-draw-step2 label {
  width: 100%;
  margin: 10px 0 0 0;
}

.hidden-tr {
  visibility: hidden;
}

/* ==== INTROJS ==== */
.introjs-button {
  background: #0078ae;
  color: white;
  text-shadow: none;
  margin-left: 4px;
  font-family: "open_sanssemibold", sans-serif;
  font-size: 12px;
  padding: 5px 8px;
  line-height: 14px;
  border-radius: 0;
  margin-bottom: 5px;
}

.introjs-button:hover {
  background: white;
  color: #0078ae;
}

.introjs-tooltip {
  font-family: "open_sanssemibold", sans-serif;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  border-radius: 0px;
}

.introjs-tooltipbuttons {
  text-align: center;
  shadow: none;
  border-color: #0078ae;
}

.introjs-skipbutton {
  margin-left: 5px;
  margin-right: 50px;
}

.introjs-tooltiptext strong {
  font-weight: bold;
  font-size: 14px;
  color: #0078ae;
}

.introHeader {
  font-weight: bold;
  font-size: 14px;
  color: #0078ae;
}

.introjs-tooltiptext ul, .introjs-tooltiptext ol {
  text-align: left;
  width: 190px;
}

.introjs-disabled {
  background-color: #ffffff;
  border-color: #cccccc;
  color: #cccccc;
}

.introjs-fixParent {
  position: absolute !important;
}

/* ==== updated globals ==== */
.content-container {
  margin-top: 20px;
  background-color: whitesmoke;
}

.content-container .panel {
  padding: 20px;
  display: inline-block;
  width: 100%;
}

.content-container .panel h2 {
  color: #0078ae;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.content-container .datepicker-container {
  padding: 0;
}

.datepicker-container button {
  padding: 12px 14px;
}

.datepicker-container button .glyphicon {
  font-size: 14px;
}

.content-container .panel label {
  margin: 10px 0 5px 0;
  line-height: 18px;
  font-size: 12px;
}

.radio.stacked label {
  padding: 0;
  margin-bottom: 0;
  line-height: 20px;
}

.content-container .panel .button-group {
  margin-top: 20px;
}

.popover-icon {
  vertical-align: bottom;
}

.bucket {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.bucket.highlight,
.bucket:hover {
  position: relative;
  border-bottom: 5px solid #0078ae;
}

.sort-filters a {
  text-decoration: none;
  cursor: pointer;
  margin: 20px 30px 20px 0;
  display: inline-block;
}

.triangle-on {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #0077ae transparent transparent transparent;
  position: absolute;
  left: 45%;
  bottom: -10px;
}

.triangle-off {
  border: none;
}

.actions-box {
  width: 25%;
  padding: 20px;
  background-color: #F0F0F0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  color: #0078ae;
  text-transform: uppercase;
  position: relative;
  flex-wrap: wrap;
}

.actions-box div {
  width: 100%;
  cursor: pointer;
  padding-left: 10px;
}

.actions-box a {
  font-size: 14px;
}

.side-box {
  position: absolute;
  left: -240px;
  top: 0;
  width: 220px;
  padding-bottom: 10px;
}

.bold {
  font-weight: bold;
}

.normal-weight {
  font-weight: normal;
}

.icon.grey {
  color: transparent;
  fill: #bbbbbb;
}

.selected-item {
  outline: solid 1px #0078ae;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #0078ae;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #0078ae;
}

.vertical-text-area {
  resize: vertical;
}

.vertical-align-middle {
  display: flex;
  align-items: center;
}

.vertical-align-baseline {
  display: flex;
  align-items: baseline;
}

.horizontal-align-middle {
  display: flex;
  justify-content: center;
}

.equal-height {
  display: flex;
  flex-wrap: wrap;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.vertical-timeline {
  padding: 0;
  list-style: none;
}

.vertical-timeline-entry {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.vertical-timeline-entry .vertical-timeline-date {
  display: flex;
  flex-direction: column;
  text-align: right;
  flex: 1 35%;
  margin: 0 0 20px 0;
}
.vertical-timeline-entry .vertical-timeline-date h1 {
  font-weight: bold;
  font-size: 26px;
  color: black;
  margin: 0;
}
.vertical-timeline-entry .vertical-timeline-date h5 {
  margin: 0;
  text-transform: uppercase;
}

.vertical-timeline-entry .vertical-timeline-line {
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  min-width: 2px;
  width: 2px;
  background-color: #bbbbbb;
  align-items: center;
}
.vertical-timeline-entry .vertical-timeline-line .circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #bbbbbb;
}

.vertical-timeline-entry .vertical-timeline-label {
  display: flex;
  flex-direction: column;
  margin: 0 0 20px 0;
  flex: 1 100%;
}
.vertical-timeline-entry .vertical-timeline-label h5 {
  margin: 0;
}

.vertical-timeline-entry:last-child .vertical-timeline-date {
  margin: 0;
}
.vertical-timeline-entry:last-child .vertical-timeline-label {
  margin: 0;
}
.vertical-timeline-entry:last-child .vertical-timeline-line {
  background-color: #ffffff;
}

.align-center {
  text-align: center;
}

.fixed-height-465 {
  min-height: 465px;
  height: 465px;
  max-height: 465px;
}
.fixed-height-465 .vertical-timeline {
  max-height: 365px;
  overflow-y: auto;
}
.fixed-height-465 .table {
  max-height: 365px;
  overflow-y: auto;
}

.uppercase {
  text-transform: uppercase;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.text-align-right {
  text-align: right;
}

/* SEARCH WITH DROPDOWN */
.search-dropdown {
  background: #0078ae;
  height: 45px;
  display: inline-block;
  padding: 0;
  width: 100%;
}

.search-options {
  float: left;
  list-style-type: none;
  position: relative;
}

.search-options a {
  font-size: 16px;
  color: white;
  display: block;
  line-height: 45px;
  padding: 0 20px;
  text-decoration: none;
  border-left: 1px solid #2e2e2e;
  font-family: Montserrat, sans-serif;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  border-left: 0 none;
}

.search-options a .glyphicon-triangle-bottom {
  margin-left: 10px;
  margin-right: -10px;
}

.search-options-list {
  visibility: hidden;
  position: absolute;
  opacity: 0;
  transition: all 0.1s;
  background: #676767;
  list-style: none;
  padding: 0;
  margin: 0;
  top: 90%;
  left: 0;
  z-index: 9999;
  min-width: 200px;
}

.search-options,
.search-options-list {
  min-width: 175px;
}

.search-options-list li a {
  border-bottom: 1px solid #565656;
  line-height: 40px;
}

.search-options-list li a:hover {
  background-color: #2e2e2e;
}

.search-options:hover .search-options-list {
  visibility: visible;
  top: 100%;
  opacity: 1;
}

.rl-search-box {
  margin: 2px;
  overflow: hidden;
}

.search-box-text {
  padding: 10px 0 10px 20px;
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  border: 0 none;
  height: 41px;
  margin-right: 0;
  color: #666;
  outline: none;
  background: #fff;
  float: left;
  box-sizing: border-box;
  transition: all 0.15s;
  width: 100%;
}

.rl-search-box ::-webkit-input-placeholder { /* WebKit browsers */
  color: #999;
}

.rl-search-box :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #999;
}

.rl-search-box ::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #999;
}

.rl-search-box :-ms-input-placeholder { /* Internet Explorer 10+ */
  color: #999;
}

.col-xs-6.start-date {
  padding-left: 0;
}

.col-xs-6.end-date {
  padding-right: 0;
}

/* ==== SHIPPING DOCUMENTS ==== */
.datepicker.start {
  padding: 0 7.5px 0 0;
}

.datepicker.end {
  padding: 0 0 0 7.5px;
}

/* ==== REVERSE LOGISTICS: SEARCH BOX ==== */
.reverse-logistics .search button.has-label {
  height: 45px;
  width: 100%;
  margin-top: 33px;
}

.reverse-logistics .search .form-control {
  height: 45px;
}

.reverse-logistics .search .datepicker-container button .glyphicon-calendar {
  font-size: 17px;
  padding: 1px;
}

#btn-search {
  width: fit-content;
}

#ref-date {
  width: 73%;
}

/* ==== RL: SEARCH RESULTS (CARD) ==== */
.ticket-header {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 14px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
  display: block;
}
.ticket-header a:hover {
  cursor: pointer;
}

.ticket-links {
  float: right;
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.ticket-created {
  font-size: 14px;
  font-weight: normal;
}

.ticket-values {
  font-weight: bold;
  display: inline-block;
  display: flex;
  align-items: flex-end;
}

.card-title {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: normal;
}

.card-data {
  font-size: 18px;
}

.details-box-full-width {
  width: 100%;
  padding: 20px;
  background-color: #fff;
  position: relative;
  z-index: 1;
}

/* ==== RL: FILTERS BOX ==== */
.reverse-logistics {
  position: relative;
}

.side-box .filters .filter-group {
  margin-bottom: 20px;
}

.side-box .filters .filter-group:last-child {
  margin-bottom: 0;
}

.side-box .filters label {
  padding-left: 5px;
  margin: 0;
}

.side-box .filters ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.side-box .filters ul li {
  padding-left: 10px;
}

.side-box .filters ul li a {
  color: #333;
}

/* ==== RL: TICKET DETAILS ==== */
ul.vertical-links {
  list-style: none;
  padding-left: 0;
  text-transform: uppercase;
}

.data h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.data-detail {
  display: block;
}

.vertical-links a:hover, a:focus {
  text-decoration: none;
}

.reverse-logistics .callers, .reference-numbers {
  min-height: 235px;
  max-height: 235px;
  overflow-y: auto;
  overflow-x: hidden;
}

.totals-breakdown-popover {
  min-width: 355px;
}

.totals-breakdown-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.totals-breakdown-name {
  display: inline-block;
  width: 63%;
  padding: 0;
  margin: 0;
}

.totals-breakdowm-amount {
  display: inline-block;
  width: 35%;
  text-align: right;
  padding: 0;
  margin: 0;
}

.flex-wrap-text-ie {
  display: block;
  width: 100%;
  max-width: 100%;
  flex-shrink: 1;
}

.total-basket {
  background: #F9F9F6;
  display: flex;
  justify-content: center;
  height: 120px;
  width: 100%;
  border: 1px solid #676767;
  border-radius: 10px;
  text-align: center;
  position: relative;
}

.total-basket:focus {
  outline: none;
  box-shadow: 0 0 2pt 1pt #0078ae;
}

.total-basket .number {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  color: #0078ae;
  height: 80px;
}

.total-basket .title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #676767;
  height: auto;
  min-height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
  padding: 0 2px;
  word-wrap: normal;
  white-space: normal;
}

.total-basket .subtitle {
  font-size: 10px;
  margin-top: 3px;
  text-transform: capitalize;
}

.total-basket-link:hover {
  text-decoration: none;
  cursor: pointer;
}

.total-basket-link .total-basket:hover .title {
  background-color: #0078ae;
}

.filters .filter-group {
  margin-bottom: 20px;
  padding: 0;
}

.filters label {
  margin: 0 !important;
}

.filters ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.filters ul li {
  padding-left: 5px;
}

.filters ul li a {
  color: #333;
  text-decoration: none;
}

.filters .input-group input::placeholder {
  font-size: 13px;
}

.filters .input-group button {
  padding: 12px 14px;
}

.filters .input-group button .glyphicon {
  font-size: 14px;
}

.tag {
  margin-bottom: 10px;
}

.tag a {
  text-decoration: none;
  cursor: pointer;
}

.tag-orange {
  background-color: #108de0;
  color: white;
  padding: 4px;
  border-radius: 10%;
  font-weight: bold;
  font-size: 12px;
  max-width: 60px;
  text-align: center;
}

.tag a .tag-orange:hover {
  background-color: #BB6325;
}

.tag-blue {
  background-color: #0078AE;
  color: white;
  padding: 4px;
  border-radius: 10%;
  font-weight: bold;
  font-size: 12px;
  max-width: 60px;
  text-align: center;
}

.tag-blue:hover {
  background-color: #0A4662;
}

.tag-grey {
  background-color: #676767;
  color: white;
  padding: 4px;
  border-radius: 10%;
  font-weight: bold;
  font-size: 12px;
  max-width: 60px;
  text-align: center;
}

.tag-grey:hover {
  background-color: #4D4D4D;
}

.card-header {
  font-size: 18px;
  padding-bottom: 14px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
  display: block;
}
.card-header h3 {
  margin: 0;
}
.card-header .card-header-detail {
  margin-top: 10px;
  font-size: 12px;
}

.card-right-header {
  float: right;
  text-align: right;
}

.disable-cursor {
  cursor: text !important;
}

.flex-column .flex-right {
  align-self: flex-end;
}

.quote-history .actions-box a:hover {
  text-decoration: none;
}

.quote-history .actions-box h3 {
  font-size: 17px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-align: center;
}

.quote-history .actions-box h1 {
  font-size: 32px;
  font-weight: 900;
  margin: 5px 0;
  padding: 0;
  text-align: center;
}

.quote-history .actions-box div {
  padding: 0;
}

.introjs-button {
  background: #0078ae;
  color: white;
  text-shadow: none;
  margin-left: 4px;
  font-family: "open_sanssemibold", sans-serif;
  font-size: 12px;
  padding: 5px 8px;
  line-height: 14px;
  border-radius: 0;
  margin-bottom: 5px;
}

.introjs-button:hover {
  background: white;
  color: #0078ae;
}

.introjs-tooltip {
  font-family: "open_sanssemibold", sans-serif;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  border-radius: 0px;
}

.introjs-tooltipbuttons {
  text-align: center;
  shadow: none;
  border-color: #0078ae;
}

.introjs-skipbutton {
  margin-left: 5px;
  margin-right: 50px;
}

.introjs-tooltiptext strong {
  font-weight: bold;
  font-size: 14px;
  color: #0078ae;
}

.introHeader {
  font-weight: bold;
  font-size: 14px;
  color: #0078ae;
}

.introjs-tooltiptext ul, .introjs-tooltiptext ol {
  text-align: left;
  width: 190px;
}

.introjs-disabled {
  background-color: #ffffff;
  border-color: #cccccc;
  color: #cccccc;
}

.introjs-fixParent {
  position: absolute !important;
}

.bold-font {
  font-family: "open_sanssemibold";
}

.regular-weight {
  font-family: "open_sansregular";
  font-weight: normal;
}

.btn.active, .btn-xs .btn-link.active {
  text-decoration: underline;
}

.inline-block {
  display: inline-block;
}

.radio-no-spacing input[type=radio] {
  display: none;
}
.radio-no-spacing input[type=radio] + label {
  font-size: 14px;
  line-height: normal;
  padding-top: 0px;
}

.push-right-sm {
  padding-left: 30px;
}

.push-right-xs {
  padding-left: 15px;
}

.push-left-xs {
  padding-right: 15px;
}

.display-block {
  display: block;
}

.position-absolute {
  position: absolute;
}

.width-80 {
  width: 80%;
}

.background-blue {
  background-color: #0078ae;
}

.background-black {
  background-color: black;
}

.background-transparent-black {
  background: rgba(0, 0, 0, 0.7);
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.font-size-14 {
  font-size: 14px;
}

.volume-quote-info-modal-heading {
  width: 50%;
  float: left;
}

.volume-quote-info-modal-description {
  margin-bottom: 15px;
}

.volume-quote-info-modal-description-list {
  padding-left: 15px;
}

.volume-quote-info-modal-description-list li {
  margin: 10px 0px 10px 0px;
}

.freight-info-label {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.freight-info-link {
  display: flex;
  justify-content: end;
  width: max-content;
  margin-bottom: 5px;
  font-size: 12px;
}

.freight-info-modal-heading {
  width: 50%;
  float: left;
}

.freight-text-heading {
  margin-bottom: 2px;
}

.freight-info-modal-footer {
  border: none;
}

.clearfix::after {
  content: "";
  clear: both;
  display: block;
}

.freight-loading-img {
  width: 5%;
}

.freightclass-info-message {
  color: #0078ae;
  padding-right: 10px;
  text-align: right;
  font-weight: bold;
}

.glyphicon-info-sign {
  font-size: 18px;
  color: #337ab7;
}

.density-freightclass-div {
  margin-top: 3px;
}

.density-freightclass-text {
  margin: 0;
}

.density-freightclass-div .glyphicon-alert {
  color: #e8af01;
}

.use-freight-link:hover {
  cursor: pointer;
  text-decoration: none;
}

.padding-top-4 {
  padding-top: 4px;
}

.word-wrap-break {
  word-wrap: break-word;
}

.height-100 {
  height: 100px;
}

.scroll-clean-white {
  scrollbar-base-color: white;
  scrollbar-face-color: white;
  scrollbar-3dlight-color: white;
  scrollbar-highlight-color: white;
  scrollbar-track-color: white;
  scrollbar-arrow-color: white;
  scrollbar-shadow-color: #7f7f7f;
  scrollbar-dark-shadow-color: white;
}

.scroll-clean-white::-webkit-scrollbar {
  width: 12px;
}

.scroll-clean-white::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.scroll-clean-white::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
}

.no-scroll-visible {
  scrollbar-base-color: whitesmoke;
  scrollbar-face-color: whitesmoke;
  scrollbar-3dlight-color: whitesmoke;
  scrollbar-highlight-color: whitesmoke;
  scrollbar-track-color: whitesmoke;
  scrollbar-arrow-color: whitesmoke;
  scrollbar-shadow-color: whitesmoke;
  scrollbar-dark-shadow-color: whitesmoke;
}

.no-scroll-visible::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.superscript {
  padding-left: 3px;
  font-weight: 600;
  font-size: 85%;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-bottom-0 {
  margin-bottom: 0px;
}

.commodity-checkbox-list {
  list-style: none;
  padding-left: 0;
}

.commodity-checkbox-list li a {
  display: inline-block;
  font-size: 13px;
  line-height: 16px;
  color: #363738;
  text-decoration: none;
  padding: 3px 0 3px 0;
  vertical-align: middle;
}

.commodity-checkbox-list.horizontal li {
  display: inline-block;
  padding-left: 0;
  float: left;
}

.order-item-card .commodity-checkbox-list li a {
  display: inline-block;
  font-size: 13px;
  line-height: 16px;
  color: #363738;
  text-decoration: none;
  padding: 25px 0 3px 0;
  vertical-align: middle;
}

.heading-line {
  overflow: hidden;
}
.heading-line > span {
  position: relative;
  display: inline-block;
}
.heading-line > span:before, .heading-line > span:after {
  content: "";
  position: absolute;
  top: calc(50% - 0.5px);
  border-bottom: 1px solid #D6D6D6;
  width: 1500px;
}
.heading-line > span:before {
  right: 100%;
  margin-right: 10px;
}
.heading-line > span:after {
  left: 100%;
  margin-left: 10px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-25 {
  padding-top: 25px;
}

.warning-message {
  border-left: 10px solid #ffcc00;
  padding: 15px;
  background: #ececec;
}

.quote-summary-hazmat-icon {
  margin-top: -5px;
  padding-top: 1px;
  padding-bottom: 0px;
  width: 13px;
  height: 13px;
  vertical-align: middle;
  color: transparent;
  fill: #023750;
}

.display-inline-block {
  display: inline-block;
}

.gaq-results-hazmat {
  margin-top: -6px;
  padding-top: 5px;
}

.volumeQuoteIdTopTextArea {
  border-bottom-style: hidden !important;
  resize: none;
}

.volumeQuoteIdBottomTextArea {
  border-top-style: hidden !important;
}

#ltlQuoteLabel {
  float: left;
}

.tlQuoteType {
  padding-top: 10px;
  margin-bottom: -10px;
}

#volumeQuoteDescription {
  float: left;
  padding-left: 10px;
}

.badge.badge-pill {
  border-radius: 1em;
  border-width: 2px;
  border-style: solid;
  border-color: #023750;
  margin: 2px;
  text-transform: none;
  font-weight: 700;
  font-size: 12px;
  float: left;
  background-color: #E8F9FE;
  text-decoration-color: #023750;
}

.badge {
  display: inline;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #023750;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.grid-quote {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  align-items: baseline;
}

.banner-single-ui {
  padding: 16px 16px 16px 32px;
  background: linear-gradient(to right, #1A3D6E, #409DE3);
  margin-right: 1em;
  margin-top: 1em;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}
.banner-single-ui .info-label-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}
.banner-single-ui .info-label-wrapper .info-icon {
  background: #fff;
  padding: 4px;
  border-radius: 4px;
}
.banner-single-ui .info-label-wrapper .info-label {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
.banner-single-ui .info-actions {
  display: flex;
  gap: 16px;
}
.banner-single-ui .info-actions .more-info-button {
  background: transparent;
  padding: 8px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
}
.banner-single-ui .info-actions .more-info-button:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
.banner-single-ui .info-actions .close-button {
  background: transparent;
  padding: 8px 14px;
  color: #fff;
  border: none;
  border-radius: 4px;
}
.banner-single-ui .info-actions .close-button:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.on-boarding-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1100;
}
.on-boarding-modal-wrapper .on-boarding-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1101;
  background-color: #fff;
  border-radius: 16px;
  width: 512px;
}
.on-boarding-modal-wrapper .on-boarding-modal .modal-image {
  width: 100%;
  height: 288px;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
}
.on-boarding-modal-wrapper .on-boarding-modal .close-modal-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background: white;
  border: none;
  color: #46484A;
  font-size: 18px;
  padding: 14px;
  border-radius: 50%;
}
.on-boarding-modal-wrapper .on-boarding-modal .close-modal-button:hover {
  background-color: #ebebeb;
  border-color: rgb(0, 73, 134);
  border-width: 2px;
}
.on-boarding-modal-wrapper .on-boarding-modal .modal-content {
  padding: 48px;
  height: 344px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 16px 16px;
}
.on-boarding-modal-wrapper .on-boarding-modal .modal-content .modal-content-info {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 16px;
}
.on-boarding-modal-wrapper .on-boarding-modal .modal-content .modal-content-info .modal-info-label {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.5px;
  text-transform: none;
  font-weight: 600;
  font-style: normal;
  color: #212121;
  margin: 0px;
}
.on-boarding-modal-wrapper .on-boarding-modal .modal-content .modal-content-info .modal-info-body {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-transform: none;
  font-weight: 400;
  font-style: normal;
  color: #63676A;
}
.on-boarding-modal-wrapper .on-boarding-modal .modal-content-actions {
  display: flex;
  justify-content: space-between;
}
.on-boarding-modal-wrapper .on-boarding-modal .modal-content-actions .modal-action-button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  box-shadow: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-transform: none;
  min-width: 60px;
  min-height: 24px;
  height: 56px;
  outline: 0px;
  margin: 0px;
  text-decoration: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 72px;
  white-space: nowrap;
  padding: 16px 24px;
  border-width: 2px;
  border-style: solid;
  border-image: initial;
  width: 140px;
}
.on-boarding-modal-wrapper .on-boarding-modal .modal-content-actions .modal-action-button.prev {
  background-color: transparent;
  color: rgb(0, 113, 199);
  border-color: rgb(229, 230, 231);
}
.on-boarding-modal-wrapper .on-boarding-modal .modal-content-actions .modal-action-button.prev:hover {
  background-color: rgba(0, 0, 0, 0.08);
  color: rgb(0, 73, 134);
  border-color: rgb(0, 73, 134);
  border-width: 2px;
}
.on-boarding-modal-wrapper .on-boarding-modal .modal-content-actions .modal-action-button.prev:disabled {
  background-color: transparent;
  color: rgb(170, 174, 176);
  border-width: 2px;
  border-style: solid;
  border-color: rgb(170, 174, 176);
  cursor: unset;
}
.on-boarding-modal-wrapper .on-boarding-modal .modal-content-actions .modal-action-button.next {
  background-color: rgb(0, 113, 199);
  color: rgb(255, 255, 255);
  border-color: rgb(0, 113, 199);
}
.on-boarding-modal-wrapper .on-boarding-modal .modal-content-actions .modal-action-button.next:hover {
  background-color: rgb(0, 73, 134);
}
.on-boarding-modal-wrapper .on-boarding-modal .modal-content-actions .modal-action-button.next:disabled {
  background-color: rgb(211, 213, 214);
  border-color: rgb(170, 174, 176);
  color: rgb(170, 174, 176);
  cursor: unset;
}
.on-boarding-modal-wrapper .on-boarding-modal .modal-content-actions .modal-pagination-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.on-boarding-modal-wrapper .on-boarding-modal .modal-content-actions .modal-pagination-wrapper .modal-pagination {
  margin: 0px;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: none;
  font-weight: 400;
  font-style: normal;
  color: rgb(33, 33, 33);
}
.on-boarding-modal-wrapper .on-boarding-modal .modal-content-actions .modal-pagination-wrapper .modal-pagination-page-wrapper {
  display: flex;
  gap: 3px;
}
.on-boarding-modal-wrapper .on-boarding-modal .modal-content-actions .modal-pagination-wrapper .modal-pagination-page-wrapper .modal-pagination-page {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #E5E6E7;
}
.on-boarding-modal-wrapper .on-boarding-modal .modal-content-actions .modal-pagination-wrapper .modal-pagination-page-wrapper .modal-pagination-page.active {
  background-color: #42ADFF;
}

/*** FOOTER STYLES ***/
.site-footer {
  background: #ffffff;
  border-top: 1px solid #e7e7e7;
  padding: 20px;
}
.site-footer .single-ui-footer-container {
  max-width: 1824px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 48px;
}
.site-footer .btn-links {
  margin-top: 7px;
}

.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.checkbox label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}

.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9 ;
}

.checkbox + .checkbox {
  margin-top: -5px;
}

.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
}

.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}

input[type=checkbox] {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type=checkbox] {
  margin: 4px 0 0;
}

input[type=checkbox]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

input[type=checkbox] {
  position: relative;
}
input[type=checkbox]::before {
  position: absolute;
  color: rgba(0, 120, 174, 0);
  left: -1px;
  top: -2px;
  width: 16px;
  height: 16px;
  border: 1px solid darkgray;
  cursor: pointer;
  opacity: 1;
}
input[type=checkbox]:not(:disabled)::before {
  background: white;
}
input[type=checkbox]:not(:disabled):checked::before {
  color: #0078ae;
  border-color: #0078ae;
}
input[type=checkbox]:disabled::before {
  background: #ebebe4;
  cursor: not-allowed;
}

input[type=checkbox]::before {
  content: "✔";
  border-radius: 3px;
}
input[type=checkbox]:not(:disabled):checked::before {
  font-size: 14px;
  font-weight: bolder;
  line-height: 1;
  padding-left: 0.5px;
}

.checkbox:nth-child(1) {
  margin-top: 0;
}
.checkbox.disabled {
  color: #adadad;
  cursor: not-allowed;
}

.checkbox-inline {
  margin-right: 10px;
}

label .ns-icon.ns-info::before {
  font-size: 120%;
  cursor: pointer;
}
label.required {
  position: relative;
}
label.required::after {
  background: transparent;
  color: #da0303;
  font-size: 12px;
  padding-left: 5px;
  line-height: 15px;
  top: 5px;
  left: 0;
  border-radius: 50%;
  content: "*";
}

.error-message {
  border-left: 10px solid #da0303;
  padding: 15px;
  background: #ececec;
}
.error-message .glyphicon-remove-sign {
  color: #da0303;
  font-size: 20px;
  float: left;
}

.Truckload-Haz-error-message {
  border-left: 10px solid #da0303;
  padding: 15px;
  background: #ececec;
  margin-bottom: 15px;
}

.Truckload-Haz-error-message h3 {
  color: #da0303;
}

.transactional-tl-button {
  float: left;
  width: 130px;
  height: 120px;
  margin-right: 20px;
  outline: none;
  text-align: center;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 5px #676767;
  display: inline-block;
}

.mode-divider {
  border-left: 1px solid #AAAEB0;
  height: 120px;
  display: inline-block;
  float: left;
  margin-right: 50px;
  margin-left: 30px;
}

.parcel-mode {
  display: inline-block;
}

.external-link {
  display: inline-block !important;
  height: 23px !important;
  width: 23px !important;
  padding-left: 3px;
  padding-bottom: 3px;
}

.parcel-icon {
  height: 54px;
  width: 54px;
  margin-bottom: 7px;
  padding-top: 10px;
}

.centering-icon {
  display: block;
  margin: auto;
}

.center-tl-button-text {
  display: inline-block;
  font-size: 20px;
}

/*** HEADER STYLES ***/
.navbar {
  background-color: #ffffff;
}
.navbar.single-ui-header {
  background-color: rgb(11, 47, 77);
}
.navbar.single-ui-header .container-fluid {
  padding: 16px 40px 0 40px;
}
@media (min-width: 1440px) {
  .navbar.single-ui-header .container-fluid {
    padding: 16px 48px 0 48px;
  }
}
.navbar.single-ui-header .single-ui-container-fluid {
  max-width: 1824px;
}
.navbar.single-ui-header .navbar-header {
  margin-bottom: 8px;
}
.navbar.single-ui-header .navbar-search {
  width: 328px;
  display: flex;
}
.navbar.single-ui-header .navbar-search .form-control {
  height: 40px;
}
.navbar.single-ui-header .navbar-search #headerSearch {
  border: 0px;
  padding: 8px 8px 8px 16px;
  height: 24px;
  box-sizing: content-box;
  border-radius: 4px 0px 0px 4px;
}
.navbar.single-ui-header .navbar-search .btn-default {
  height: 40px;
  border-radius: 0px 4px 4px 0px;
}
.navbar.single-ui-header .navbar-search .btn-default .glyphicon {
  font-size: 16px;
}
.navbar.single-ui-header .navbar-brand {
  padding-top: 0px;
}
.navbar.single-ui-header .navbar-brand img {
  height: 40px;
}
.navbar.single-ui-header .navbar-nav li {
  margin-right: 32px;
}
.navbar.single-ui-header .navbar-nav li a {
  padding: 16px 0px;
  margin: 0px;
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0.15px;
  line-height: 24px;
  height: 100%;
  color: rgb(54, 56, 58);
  border: none;
}
.navbar.single-ui-header .navbar-nav li a.active {
  border-bottom: solid rgb(0, 113, 199);
}
.navbar.single-ui-header .navbar-nav .dropdown-menu {
  width: 244px;
}
.navbar.single-ui-header .navbar-nav .dropdown-menu li {
  margin-right: 0px;
}
.navbar.single-ui-header .navbar-nav .dropdown-menu li a {
  color: rgb(17, 17, 18);
  font-size: 14px;
  letter-spacing: 0.5px;
  display: block;
  padding: 6px 16px 6px 8px;
  text-wrap: wrap;
}
.navbar.single-ui-header .navbar-nav .dropdown-menu li a:hover, .navbar.single-ui-header .navbar-nav .dropdown-menu li a.active {
  color: #108de0;
  background-color: rgba(0, 113, 199, 0.08);
}
.navbar.single-ui-header .single-ui-header-nav {
  display: flex;
  position: absolute;
  right: 0px;
  left: -1px;
  justify-content: center;
  background: #fff;
}
.navbar.single-ui-header .single-ui-header-nav ul {
  max-width: 1824px;
  margin: 0;
}
.navbar.single-ui-header .navbar-icons {
  float: unset !important;
  display: flex;
  justify-content: flex-end;
}
.navbar.single-ui-header #app-chooser-dropdown .navbar-btn {
  color: #FFF;
  margin: 4px;
  padding: 8px;
  border-radius: 100%;
}
.navbar.single-ui-header #app-chooser-dropdown .navbar-btn:hover, .navbar.single-ui-header #app-chooser-dropdown .navbar-btn:focus {
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.08);
}
.navbar.single-ui-header #app-chooser-dropdown .navbar-btn:hover .icon, .navbar.single-ui-header #app-chooser-dropdown .navbar-btn:focus .icon {
  fill: #FFF;
}
@media (max-width: 767px) {
  .navbar.single-ui-header #app-chooser-dropdown .navbar-btn {
    margin-top: 4px;
  }
}
.navbar.single-ui-header #app-chooser-dropdown .icon {
  fill: #FFF;
  width: 16px;
  height: 16px;
  margin: 0px 8px;
}
.navbar.single-ui-header #user-profile-dropdown .navbar-btn {
  color: #FFF;
  border-radius: 26px;
  padding: 4px 16px;
  margin-left: 8px;
  margin-top: 4px;
}
.navbar.single-ui-header #user-profile-dropdown .navbar-btn:hover, .navbar.single-ui-header #user-profile-dropdown .navbar-btn:focus {
  background-color: rgba(0, 0, 0, 0.08);
}
.navbar.single-ui-header #user-profile-dropdown .icon {
  fill: #FFF;
}
.navbar.single-ui-header .userName {
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
}
.navbar.single-ui-header .navisphere-brand {
  width: calc(100% + 80px);
  background-color: #FFF;
  margin-left: -40px;
  padding-left: 40px;
  padding-right: 40px;
}
@media (min-width: 1440px) {
  .navbar.single-ui-header .navisphere-brand {
    width: calc(100% + 96px);
    margin-left: -48px;
    padding-left: 48px;
    padding-right: 48px;
  }
}
.navbar.single-ui-header .navbar-form {
  margin-top: 0px;
  margin-right: 4px;
}
@media (max-width: 767px) {
  .navbar.single-ui-header .navbar-form {
    padding-right: 15px;
  }
  .navbar.single-ui-header .navbar-form .navbar-search {
    width: 100%;
  }
  .navbar.single-ui-header .navbar-form .navbar-search .form-control {
    border-radius: 4px 0px 0px 4px;
  }
  .navbar.single-ui-header .navbar-form .navbar-search .input-group-btn {
    width: unset;
  }
}
.navbar.single-ui-header .navbar-toggle:hover, .navbar.single-ui-header .navbar-toggle:focus {
  background-color: rgba(0, 0, 0, 0.08);
}
.navbar.single-ui-header .navbar-toggle .icon-bar {
  background-color: #FFF;
}

.navbar-brand > img {
  height: 28px;
}

.navisphere-brand {
  color: #676767;
}
.navisphere-brand .dropdown-menu > li > a {
  font-size: 12px;
  color: #676767;
}
.navisphere-brand .dropdown-menu {
  overflow: hidden;
  max-height: none;
}

.navisphere-brand > li > a {
  height: auto;
  font-size: 12px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

.navisphere-brand.navbar-nav > li > a:hover,
.navisphere-brand.navbar-nav > li > a:focus,
.navisphere-brand .dropdown.open > a:hover,
.navisphere-brand .dropdown.open > a:focus {
  background-color: transparent;
  color: #108de0;
}

.navisphere-brand > li > a.active,
.navisphere-brand .dropdown.open > a.active,
.navisphere-brand > li > a.active:hover,
.navisphere-brand > li > a.active:focus {
  color: #108de0;
  font-weight: bold;
  border-bottom: solid #108de0;
  border-width: 0 0 5px 0;
  height: 50px;
}
@media (max-width: 767px) {
  .navisphere-brand > li > a.active,
  .navisphere-brand .dropdown.open > a.active,
  .navisphere-brand > li > a.active:hover,
  .navisphere-brand > li > a.active:focus {
    background: transparent;
    border-bottom: none;
    border-left: thick solid #108de0;
    text-align: left;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

.newNotificationBadge {
  height: 10px;
  width: 10px;
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
}

.newNotificationBadge + a {
  display: inline-block !important;
  padding-left: 10px !important;
}

.nav.navisphere-brand .dropdown-menu > li > a:hover,
.nav.navisphere-brand .dropdown-menu > li > a:focus,
.navbar .navisphere-brand .open .dropdown-menu > li > a:hover,
.navbar .navisphere-brand .open .dropdown-menu > li > a:focus,
.navisphere-brand.navbar-nav .open .dropdown-menu > li > a.active {
  color: #108de0;
  background: transparent;
  border-bottom: none;
}

.navisphere-brand.navbar-nav .open .dropdown-menu > li > a.active {
  font-weight: bold;
}

.navbar-default .navbar-nav > .open > a {
  background: transparent;
}

.navisphere-brand .open > a,
.navisphere-brand .open > a:hover,
.navisphere-brand .open > a:focus {
  border-color: #D6D6D6;
}

.navbar-default .navbar-icons {
  color: #676767;
}
.navbar-default .navbar-icons .caret {
  margin-left: 9px;
}
.navbar-default .navbar-icons .navbar-btn {
  height: 34px;
  background-color: transparent;
  border: none;
  padding: 4px 0px;
  margin: 8px 10px 0 0;
  color: #676767;
}
@media (max-width: 767px) {
  .navbar-default .navbar-icons .navbar-btn {
    margin-top: 12px;
  }
}
.navbar-default .navbar-icons .navbar-btn:hover,
.navbar-default .navbar-icons .navbar-btn:focus {
  color: #108de0;
  border: none;
  background-color: white;
}
.navbar-default .navbar-icons .icon {
  width: 23px;
  height: 23px;
  fill: #676767;
}
.navbar-default .navbar-icons .navbar-btn:hover .icon,
.navbar-default .navbar-icons .navbar-btn:focus .icon {
  fill: #108de0;
}
.navbar-default .navbar-icons .dropdown-menu > li > a {
  color: #676767;
  font-size: 14px;
}
.navbar-default .navbar-icons .dropdown-menu {
  top: 46px;
  margin-right: 10px;
  padding: 5px, 0;
}
.navbar-default .navbar-icons .dropdown-menu .divider {
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-default .navbar-icons .open > a, .navbar-default .navbar-icons .open > a:hover, .navbar-default .navbar-icons .open > a:focus, .navbar-default .navbar-icons .open > a:active, .navbar-default .navbar-icons li > a:hover, .navbar-default .navbar-icons li > a:focus {
  color: #108de0;
  border: none;
  background-color: white;
  cursor: pointer;
}

.app-chooser {
  width: 300px;
  cursor: default;
}
.app-chooser li {
  padding: 0;
}
.app-chooser a {
  display: block;
  text-decoration: none;
  font-weight: 400;
  line-height: 1.42857;
  color: rgb(103, 103, 103);
  clear: both;
  white-space: nowrap;
  cursor: pointer;
  padding: 8px 15px;
}
.app-chooser a:hover {
  color: #108de0;
}
.app-chooser .current-app {
  color: #108de0;
  padding: 8px 12px;
  border-left: 3px solid #108de0;
}

.user-account {
  min-width: 300px;
  padding: 0 5px;
  cursor: default;
}
.user-account h4 {
  margin: 0 0 5px 0;
}
.user-account p {
  margin: 0;
  font-size: 14px;
}
.user-account .circle-border {
  width: 45px;
  height: 45px;
  border: 1px solid #676767;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}
.user-account .circle-border .initials {
  position: relative;
  top: 9px;
  font-size: 18px;
  color: #676767;
  font-weight: bold;
  text-transform: uppercase;
}

.navbar-default .navbar-toggle {
  margin-right: 0;
  border-color: transparent;
}

.navbar-search .form-control {
  height: 34px;
}
.navbar-search .btn.btn-default {
  height: 34px;
  margin: 0;
  padding: 6px 12px;
  background-color: white;
  color: #676767;
  border: none;
}
.navbar-search .btn.btn-default .glyphicon {
  font-size: 23px;
  margin-right: 5px;
}
.navbar-search .btn.btn-default:focus, .navbar-search .btn.btn-default:hover, .navbar-search .btn.btn-default:active {
  color: #108de0;
  box-shadow: none;
}

.navbar-form {
  border: 0;
  padding: 0 0 0 15px;
}

.pageStartingPoint {
  position: relative;
  margin-top: 95px;
  height: 5px;
}

.site-maintenance-banner-section {
  text-align: center;
  background-color: #F5F5F5;
  padding: 3px 0 8px 0;
  z-index: 1;
  width: 970px;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 992px) {
  .site-maintenance-banner-section {
    width: 100%;
    padding-top: 8px;
  }
}

.site-maintenance-banner {
  text-align: center;
  padding: 8px 10px 8px 10px;
  border: 1px solid lightgray;
  border-left: 8px #005CB9 solid;
  background-color: #FFFFFF;
}

.site-maintenance-banner span.strong {
  font-weight: bolder;
}

.site-maintenance-banner-icon {
  margin-right: 10px;
  color: #E8AF01;
}

.site-maintenance-banner-header {
  font-weight: bold;
  margin-right: 3px;
}

.maintenance-banner-close-button {
  display: inline-block;
  margin-left: 13px;
  color: #A2A2A2;
}

.beta-details-page-banner-section {
  text-align: center;
  background-color: #3091bc solid;
  padding: 3px 0 8px 0;
  z-index: 1;
}

.beta-details-page-banner {
  text-align: center;
  padding: 8px 10px 8px 10px;
  border: 1px solid #3091bc;
  border-left: 8px #3091bc solid;
  background-color: #FFFFFF;
  width: 77%;
  margin-top: 45px;
  Cursor: pointer;
}

.beta-details-page-banner span.strong {
  font-weight: bolder;
}

.beta-details-page-banner-icon {
  margin-right: 10px;
  color: #3091bc;
}

.beta-details-page-banner-header {
  font-weight: bold;
  margin-right: 3px;
}

.page-retirement-banner-icon {
  top: 3px;
  font-size: 18px;
  margin-right: 10px;
  color: #005CB9;
}

.page-retirement-banner-text {
  font-size: 14px;
}

.wizard-selections {
  position: relative;
  height: 110px;
  width: 324px;
  padding: 4px;
  border: 1px solid #ddd;
  background: whitesmoke;
  z-index: 10;
  outline: none !important;
}

.wizard-selections:before,
.wizard-selections:after {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  z-index: 10;
}

.wizard-selections.active-widget:before {
  border-width: 1px;
  right: -22px;
  border-left: 21px solid #979797;
  border-top: 54px solid transparent;
  border-bottom: 54px solid transparent;
}

.wizard-selections.active-widget:after {
  border-left: 20px solid white !important;
  border-top: 54px solid transparent;
  border-bottom: 54px solid transparent;
}

.wizard-selections-narrow {
  position: relative;
  height: 90px;
  width: 250px;
  padding: 2px;
  border: 1px solid #ddd;
  background: whitesmoke;
  z-index: 10;
  outline: none !important;
}

.wizard-selections-narrow:before,
.wizard-selections-narrow:after {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  z-index: 10;
}

.wizard-selections-narrow.active-widget:before {
  border-width: 1px;
  right: -22px;
  border-left: 21px solid #979797;
  border-top: 45px solid transparent;
  border-bottom: 45px solid transparent;
}

.wizard-selections-narrow.active-widget:after {
  border-left: 20px solid white !important;
  border-top: 45px solid transparent;
  border-bottom: 45px solid transparent;
}

.wizard-area.active-widget {
  margin-left: 3px;
}

.wizard-area {
  min-height: 200px;
  padding: 4px 4px 40px;
  border-top: 1px solid rgba(151, 151, 151, 0.29);
  margin-bottom: 1px;
  z-index: 10;
  background-color: white;
}
.wizard-area:nth-last-child(2) {
  padding-bottom: 20px;
}

.wizard-widget-scroll-area {
  background-color: #fafafa;
  min-height: 440px;
  height: calc(75vh - 100px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.wizard-widget-scroll-area-long {
  background-color: #fafafa;
  min-height: 540px;
  height: calc(75vh - 100px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.wizard-selection-area {
  padding-left: 10px;
  font-weight: bold;
}

.wizard-selection-area-header {
  line-height: 26px;
  padding-left: 10px;
  vertical-align: middle;
}

.wizard-selection-area-header-center {
  margin-top: 14px;
}

.validator-icon {
  position: absolute;
  top: 0;
  left: 30px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 8px 0;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  border: 1px solid #676767;
  font-size: 150%;
  background-color: whitesmoke;
  color: transparent;
}

.validator-icon.complete {
  color: white;
  background-color: #729514;
}

.active-widget {
  background-color: white;
  border: 1px solid #979797;
  outline: none !important;
}

.wizard-active-widget {
  border: 1px solid whitesmoke;
  margin-bottom: 1px;
  z-index: 10;
  outline: none !important;
}

.create-report-form {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.account-selection-display {
  overflow: auto;
  max-height: 180px;
}

.saved-report-height {
  min-height: 80px;
}

.account-selected {
  font-size: 300%;
  background-color: transparent;
  color: white;
  text-shadow: -1px 0 grey, 0 1px grey, 1px 0 grey, 0 -1px grey;
  line-height: 50px;
  margin-right: 10px;
}

.account-selected.selected {
  color: #0078ae;
}

.account-select-all {
  font-size: 140%;
  background-color: transparent;
  line-height: 22px;
  margin-right: 10px;
}

.account-remove-all {
  font-size: 140%;
  background-color: transparent;
  line-height: 22px;
  margin-right: 10px;
}

.account-widget-scroll-area {
  overflow-y: auto;
  max-height: 230px;
}

.account-panels {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
  text-align: left;
  border: 1px solid #D6D6D6;
}

.header-margin {
  margin-top: 40px;
  margin-bottom: 40px;
}

.saved-report-icons {
  height: 30px;
}

.saved-report-icons span {
  font-size: 130%;
  background-color: transparent;
  line-height: 28px;
  margin-right: 10px;
}

.date-picker-label {
  line-height: 84px;
  vertical-align: middle;
  white-space: nowrap;
}

.split-button {
  height: 26px;
  margin-bottom: 0px;
}

.report-button {
  color: #0078ae;
}

.report-button:hover {
  color: #00008a;
}

.location-selection {
  font-size: 200%;
  background-color: transparent;
  color: white;
  text-shadow: -1px 0 grey, 0 1px grey, 1px 0 grey, 0 -1px grey;
  line-height: 50px;
  margin-right: 10px;
}

.location-selection:hover {
  color: #0078ae;
}

.location-selected {
  font-size: 150%;
  background-color: transparent;
  color: #0078ae;
  line-height: 50px;
  margin-right: 10px;
}

.location-add {
  top: 40px;
  position: absolute;
  font-size: 10px;
  color: #0078ae;
  margin-left: 3px;
}

.location-remove {
  top: 40px;
  position: absolute;
  font-size: 10px;
  color: #0078ae;
  margin-left: -6px;
}

.location-widget-scroll-area {
  overflow-y: auto;
  max-height: 380px;
}

.validator-icon-sm {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 16px;
  height: 16px;
  line-height: 14px;
  margin: 4px 0;
  padding-left: 2px;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  border: 1px solid #676767;
  font-size: 76%;
  background-color: #ececec;
  color: transparent;
}

.validator-icon-sm.complete {
  color: #ffffff;
  background-color: #7b9f1b;
}

.report-subscription-summary {
  display: table;
}
.report-subscription-summary > div {
  display: table-row;
}
.report-subscription-summary > div > div {
  display: table-cell;
}
.report-subscription-summary > div > div:first-child {
  color: gray;
  text-align: right;
}
.report-subscription-summary > div > div:first-child span {
  margin-right: 10px;
}

.subscription-icon {
  height: 20px;
  width: 20px;
}

.error-icon-sm {
  position: absolute;
  top: 4px;
  left: 0px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  margin: 4px 0;
  padding-left: 2px;
  -moz-border-radius: 144%;
  -webkit-border-radius: 144%;
  border-radius: 144%;
  border: 1px solid #676767;
  font-size: 150%;
  border: none;
  background-color: transparent;
  color: #da0303;
}

.close-icon-sm {
  position: absolute;
  width: 18px;
  height: 18px;
  line-height: 18px;
  margin: 6px 22px;
  border: none;
  font-size: 100%;
  background-color: #ffffff;
  color: #676767;
}

.toast-inner-container {
  padding: 0px !important;
  top: -60px;
  height: 60px;
  background-color: white;
  width: 300px;
  margin-right: 40px !important;
}

.toast-message {
  margin-top: 6px;
  color: black;
  font-size: 14px;
  font-weight: bold;
  padding-left: 0px;
}

.toast-sub-message {
  color: black;
  font-size: 10px;
  font-weight: 600;
}

.toast-success-left {
  width: 10px;
  height: 60px;
  background-color: #7b9f1b;
}

.toast-error-left {
  width: 10px;
  height: 60px;
  background-color: #da0303;
}

.toast-success-border {
  border: 1px solid #7b9f1b;
}

.toast-error-border {
  border: 1px solid #da0303;
}

.toast-icon {
  padding-left: 0px !important;
  margin-left: 0px;
}

/***** MAP *****/
.map-container {
  overflow: hidden;
  position: relative;
  height: calc(100vh - 101px);
  margin-top: -35px;
}

.map {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

/***** SEARCH *****/
.map-search .input-group-btn button {
  height: 40px;
  margin: 0;
  padding: 0px 15px;
}

.map-search-clear {
  position: absolute;
  z-index: 1001;
  margin-left: -20px;
  margin-top: 11px;
}

.map-search-clear .glyphicon {
  font-size: 13px;
}

/***** SIDE PANEL *****/
.side-panel {
  height: calc(100vh - 225px);
  overflow-y: auto;
  overflow-x: hidden;
}

.map-panel-header {
  position: relative;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
}
.map-panel-header div {
  width: 75%;
  height: 40px;
}
.map-panel-header h3 {
  margin: 0;
  color: white;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  align-self: center;
}
.map-panel-header a {
  color: white;
}
.map-panel-header a:hover {
  color: whitesmoke;
  cursor: pointer;
}
.map-panel-header img {
  width: 100%;
  height: 100%;
  max-width: 30px;
  max-height: 23px;
}

.map-panel-item {
  padding: 10px;
}

.fixed-bottom-left {
  position: fixed;
  left: 15px;
  bottom: 28px;
}

.btn-oval {
  background-color: #676767;
  color: #fff;
  border-radius: 50px;
  padding: 4px 12px;
  font-size: 12px;
  margin: 0;
}
.btn-oval img {
  width: 20px;
  height: 20px;
  margin-left: -8px;
  margin-right: 3px;
  border-radius: 50%;
}
.btn-oval:hover, .btn-oval:active, .btn-oval:focus {
  background-color: #484848;
  color: #ffffff;
}

.map-selector:hover {
  cursor: pointer;
  background-color: #9999BB;
}

.active-selector {
  background-color: #9999BB;
  border: none;
  outline: 0;
}

/***** INFOBOX *****/
.max-half-height {
  max-height: 50%;
}

.max-full-height {
  max-height: 100%;
}

.infobox-item {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
}

.infobox-data {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.infobox-data-button {
  border-color: #0078ae;
  background-color: #ffffff;
  color: #0078ae;
  border-radius: 5px;
}

.infobox-data-button:hover {
  border-color: #0078ae;
  background-color: #f5f5f5;
  color: #0078ae;
  border-radius: 5px;
}

.infobox-data-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.25em;
}
.infobox-data-row span {
  align-self: center;
}

.infobox-data-row.pin {
  justify-content: flex-start;
  margin-bottom: 0px;
}

.infobox-data-pin {
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 8px;
  align-self: center;
  z-index: 1000;
}

.infobox-data-dotted-line {
  height: 100%;
  min-height: 10px;
  width: 20px;
  min-width: 20px;
  background: url("../app/assets/img/InfoPanel_LocationDot.svg") center 5px repeat-y;
  background-size: 4px 8px;
}

.infobox-data-item {
  border-bottom: thin solid #ECECEC;
  font-size: 13px;
}

/***** MAP MESSAGE OVERLAY *****/
.message-overlay {
  position: fixed;
  bottom: 28px;
  background-color: #676767;
  color: white;
  z-index: 1000;
  border-radius: 5px;
}

/***** FOOTER FOR MAP *****/
.map-footer {
  bottom: 8px;
  left: 55px;
}

.MicrosoftMap .Infobox {
  display: none;
}

.switch {
  text-transform: uppercase;
  width: 62px;
  height: 26px;
  color: #0078ae;
  transition: 0.5s ease-out all;
  -webkit-transition: 0.5s ease-out all;
}

.switch small {
  background-color: #0078ae;
  height: 20.8px;
  width: 20.8px;
  top: 2.6px;
  left: 2.6px;
}

.switch.checked {
  background-color: #0078ae;
  border-color: #0078ae;
}

.switch.checked small {
  left: 38.6px;
  background-color: white;
}

.switch .off {
  color: #0078ae;
}

.switch-text {
  line-height: 14px;
}

.switch .on, .switch .off {
  top: 6px;
  line-height: 15px;
}

.dashboard-widget-area {
  margin: 10px 0 30px;
  min-height: 200px;
}

.widget-container {
  float: left;
  display: inline-block;
  width: 33%;
  padding-bottom: 1em;
}

.widget {
  margin: 0 1em 0 0;
  background-color: white;
  border: solid 1px #676767;
  position: relative;
  height: 100%;
}

.btn-toolbar {
  padding-top: 20px;
  float: left;
}

#subheader h2.text-left {
  float: left;
  padding-right: 10px;
}

#widget-dashboard .btn-toolbar .btn-warning {
  background-color: #f47b20;
}

#widget-dashboard .btn-toolbar .btn {
  font-size: 10px;
  padding: 5px;
}

#widget-dashboard .btn-toolbar .btn-group,
#widget-dashboard .btn-toolbar .btn {
  float: right;
}

#widget-dashboard .widget-header {
  overflow: hidden;
  background-color: #676767;
  position: relative;
}
#widget-dashboard .widget-header .widget-title {
  vertical-align: middle;
  color: #ffffff;
  font-size: 16px;
}
#widget-dashboard .widget-header .glyphicon {
  color: #ffffff;
  opacity: 1;
  float: none;
  margin-right: 10px;
}
#widget-dashboard .widget-header .glyphicon:hover {
  cursor: inherit;
}
#widget-dashboard .widget-header .widget-controls {
  position: absolute;
  top: 5px;
  right: 5px;
}
#widget-dashboard .widget-header .widget-controls .widget-controls-text {
  color: #ffffff;
}
#widget-dashboard .widget-header .widget-controls .widget-controls-text:hover {
  color: #D6D6D6;
}
#widget-dashboard .widget-header .widget-controls .glyphicon {
  cursor: pointer;
  float: right;
  opacity: 1;
  margin-left: 10px;
}
#widget-dashboard .widget-header .widget-controls .glyphicon:hover {
  opacity: 1;
  color: #D6D6D6;
}
#widget-dashboard .widget-header .label {
  display: inline-block;
  vertical-align: middle;
}

.widget-header form.widget-title {
  display: inline;
}

.widget-header form.widget-title input.form-control {
  width: auto;
  display: inline-block;
}

.widget-content {
  overflow: hidden;
}

.widget .widget-ew-resizer {
  position: absolute;
  width: 5px;
  right: -2px;
  height: 100%;
  top: 0;
  cursor: ew-resize;
}

.widget .widget-s-resizer {
  cursor: ns-resize;
  height: 5px;
  width: 100%;
  bottom: -7px;
  left: 0;
}

.widget .widget-resizer-marquee {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.remove-layout-icon {
  vertical-align: text-top;
  cursor: pointer;
  opacity: 0.3;
}

.remove-layout-icon:hover {
  opacity: 1;
}

.layout-title {
  display: inline-block;
}

#widget-dashboard .panel-body {
  padding: 0;
}

.dashboard-item {
  margin-top: 0;
  height: 100%;
  overflow: auto;
}

.dashboard-item-content {
  border: 1px solid transparent;
  background-color: #fff; /* bjorn */
}

#widget-dashboard .tracking-number-widget-container .tracking-number .btn {
  max-width: 100%;
  line-height: 18px;
  word-wrap: normal;
  white-space: normal;
}

.tracking-number-widget-container {
  margin: 30px 10px;
}

.tracking-number {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}

.tracking-number input {
  background-color: #f9f9f9;
  height: 60px;
  padding-left: 20px;
  font-size: 18px;
}

.tracking-number button {
  height: 60px;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 0;
}

.tracking-number button:after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right-color: #0078ae;
  border-width: 10px;
  margin-top: -10px;
}

.tracking-number button:hover:after {
  border-right-color: #286090;
}

.tracking-number button:focus:after {
  border: none;
}

#widget-dashboard .tracking-number-widget-container .create-order-btn {
  max-width: 600px;
  margin: 0 auto;
}

#widget-dashboard .tracking-number-widget-container .create-order-btn .btn {
  width: 100%;
  margin: 20px 0;
  text-transform: uppercase;
  word-wrap: normal;
  white-space: normal;
  line-height: 18px;
}

#widget-dashboard .create-order-btn .btn svg {
  fill: #0078ae;
  vertical-align: middle;
  color: #fff;
  margin-bottom: 3px;
}

.tracking-number-widget-container .help-block {
  max-width: 600px;
  margin: 5px auto 0 auto;
}

.dashboard-item-content {
  padding: 5px;
}

.widget-activity-row {
  width: 100%;
  min-height: 40px;
  border: 1px solid #676767;
  border-radius: 6px;
  margin-bottom: 5px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.widget-activity-row .fix-fluid {
  float: left;
  height: auto;
  overflow: hidden;
}

.widget-activity-row .fix-fluid.left {
  width: 67%;
  display: flex;
  justify-content: space-between;
}

.widget-activity-row .fix-fluid.right {
  width: 33%;
}

.widget-activity-row .fix-fluid div {
  display: inline-block;
  vertical-align: middle;
}

.widget-activity-row .order-number a {
  cursor: pointer;
}

.dashboard-item-content .vertical-align {
  display: flex;
  align-items: center;
  height: 50px;
}

.dashboard-item-content .at-a-glance .vertical-align {
  height: 40px;
  justify-content: center;
}

#widget-dashboard .vertical-align div {
  box-sizing: border-box; /* 2 */
  max-width: 100%; /* 1 */
}

.dashboard-item .date {
  background-color: #676767;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  width: 50px;
  height: 50px;
  position: relative;
  padding-top: 10px;
  float: left;
}

.dashboard-item .date:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #676767;
  border-width: 8px;
  margin-top: -8px;
}

.dashboard-item .date span {
  display: block;
  text-align: center;
}

.dashboard-item .date span.day {
  font-size: 26px;
  line-height: 26px;
  height: 26px;
}

.dashboard-item .date span.month {
  font-size: 10px;
  line-height: 10px;
  height: 10px;
}

.widget-activity-row .order-number {
  min-width: 60px;
  margin-left: 10px;
  margin-right: 10px;
  float: left;
}

.widget-activity-row .order-pickup span {
  justify-content: flex-end;
  text-align: right;
}

.widget-activity-row .order-pickup.calc {
  width: calc(100% - 150px);
  max-width: calc(100% - 130px);
  float: left;
  overflow: hidden;
}

.widget-activity-row .to-arrow {
  width: 18px;
  padding-left: 3px;
}

.widget-activity-row .to-arrow svg {
  fill: #676767;
  width: 16px;
  height: 16px;
}

.widget-activity-row .deliver-to.calc {
  width: calc(100% - 20px);
  max-width: calc(100% - 20px);
  float: right;
  padding-left: 2px;
  overflow: hidden;
}

.widget-activity-row .remove-row {
  position: absolute;
  top: -2px;
  right: -2px;
  color: #da0303;
  padding: 4px 4px 8px 8px;
}

.widget-activity-row .event-status {
  padding: 0 15px 0 10px;
  overflow: hidden;
}

#at-a-glance-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.at-a-glance {
  width: 120px;
  height: 120px;
  border: 1px solid #676767;
  border-radius: 10px;
  text-align: center;
  position: relative;
  margin: 10px;
}

.at-a-glance:focus {
  outline: none;
  box-shadow: 0 0 2pt 1pt #0078ae;
}

.at-a-glance .number {
  font-size: 28px;
  font-weight: bold;
  color: #0078ae;
  padding-top: 8px;
}

.at-a-glance .title {
  background-color: #676767;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 14px;
  padding: 0 2px;
  word-wrap: normal;
  white-space: normal;
}

.at-a-glance:hover .title {
  background-color: #0078ae;
}

.at-a-glance .title:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #676767;
  border-width: 10px;
  margin-left: -10px;
}

.at-a-glance:hover .title:after {
  border-bottom-color: #0078ae;
}

#widget-dashboard .widget-header .badge {
  background-color: #0078AE;
  margin-left: 5px;
}

.modal-header h3 small {
  display: none;
}

.data-hover {
  cursor: default;
}

span.city-state {
  font-size: 10px;
  display: block;
  width: 100%;
}

.widget-subscriptions .col-sm-10 {
  padding-top: 2px;
}

.widget-subscriptions .control-label {
  text-transform: uppercase;
}

.widget-subscriptions input[type=checkbox] + label {
  padding: 0;
  line-height: 28px;
}

.widget-subscriptions input[type=checkbox] + label::before {
  margin-right: 3px;
  line-height: 28px;
  font-size: 20px;
}

.widget-subscriptions .radio {
  padding: 0;
}

/* OVERRIDE HOVER FOR "OK" BUTTON */
body.modal-open .modal-dialog .modal-footer button.btn-primary:hover {
  color: #0078ae;
  background-color: #ffffff;
  border-color: #0078ae;
}

.search-invoices-form {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}
.search-invoices-form > div {
  flex: 1 1 0;
  margin-right: 16px;
}
.search-invoices-form > div:last-child {
  margin-right: 0;
}

.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 50%;
  vertical-align: middle;
  overflow: hidden;
}

.svg-content-responsive {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
}

.chart-filled {
  fill: steelblue;
}

.chart-empty {
  fill: #dedede;
}

.needle, .needle-center {
  fill: #464A4F;
}

.liquidFillGaugeText {
  font-family: Helvetica;
  font-weight: bold;
}

.panel-freightmovement {
  min-height: 150px;
}

.panel-heading-freightmovement {
  font-size: 14px;
  min-height: 60px;
  font-weight: bold;
}

.orders-overview-report-style .panel-left-space {
  margin-left: 5px;
}

.orders-overview-report-style .control-left-space {
  margin-left: 15px;
}

.orders-overview-report-style .control-top-space {
  margin-top: 10px;
}

.orders-overview-report-style .nodata {
  color: red;
}

.orders-overview-report-style .generating {
  color: blue;
}

select[multiple], select[size] {
  width: 100%;
  max-height: 140px;
}

.required-field-block select {
  padding-left: 25px;
}

.orders-overview-report-style input[type=checkbox] {
  position: relative;
  width: 14px;
  height: 14px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  opacity: 1;
  top: 2px;
}

.orders-overview-report-style div.filters {
  min-height: 200px;
}

.orders-overview-report-style .datepicker-container {
  padding-top: 15px;
}

.orders-overview-report-style .radio + .radio {
  margin-top: -10px;
}

.orders-overview-report-style input[type=radio] + label {
  padding: 0;
}

.orders-overview-report-style .radio {
  margin-top: 30px;
}

.orders-overview-report-style .account label {
  margin-top: 15px;
}

.orders-overview-report-style .datepicker-container.padbot {
  padding: 5px 0 15px 0;
}

.orders-overview-report-style .datepicker-container .form-control[readonly] {
  background-color: #fff;
}

.orders-overview-report-style .filters .required-field-block {
  margin-top: -1px;
  margin-left: -16px;
}

.orders-overview-report-style h4.required {
  position: relative;
}
.orders-overview-report-style h4.required::after {
  background: transparent;
  color: #da0303;
  font-size: 12px;
  padding-left: 5px;
  line-height: 15px;
  top: 5px;
  left: 0;
  border-radius: 50%;
  content: "*";
}

.sm-bar-chart {
  position: relative;
  clear: left;
  height: 100px;
}

svg {
  width: 100%;
}

.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 50%;
  vertical-align: middle;
  overflow: hidden;
}

.svg-content-responsive {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
}

.axis path,
.axis line {
  fill: none;
  stroke: #c2c9cd;
  shape-rendering: crispEdges;
}

.d3bar:hover {
  fill: #bcbcbc;
}

.d3-tip {
  line-height: 1;
  font-weight: bold;
  padding: 12px;
  background: rgba(0, 0, 0, 0.8);
  color: #efefef;
  border-radius: 2px;
}

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 0, 0, 0.8);
  content: "▼";
  position: absolute;
  text-align: center;
}

/* Style northward tooltips differently */
.d3-tip.n:after {
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
}

.downloadCheckDefault {
  font-size: 80%;
  background-color: transparent;
  padding-right: 5px;
  text-shadow: -1px 0 grey, 0 1px grey, 1px 0 grey, 0 -1px grey;
}

.downloadButton {
  margin-right: 1px;
  margin-bottom: 0px;
}

.downloadDropDownBorder {
  border: #0078ae 1px solid;
  margin-right: 0px;
}